<template>
    <Line :options="chartOptions" :data="chartData" :style="styles" :plugins="plugins"></Line>
</template>
<script>
    import { ref, inject, computed, onMounted } from "vue";
    import { Line } from "vue-chartjs";
    import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, LogarithmicScale, CategoryScale, PointElement, Filler } from "chart.js";
    import annotationPlugin from 'chartjs-plugin-annotation';

    ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, LogarithmicScale, CategoryScale, PointElement, Filler, annotationPlugin);

    export default {
        props: {
            chartOptions: {
                type: Object,
                default: () => { return { responsive: true }; }
            },
            chartData: {
                type: Object,
                default: () => { return {}; }
            },
            styles: {
                type: Object,
                default: () => {
                    return {
                        position: 'relative'
                    }
                }
            },
            plugins: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        components: {
            Line
        },
        setup(props, context) {

            return {

            }
        }
    }
</script>
