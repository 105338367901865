<template>
	<div>
		<br />
		<o-field grouped>
			<h1 v-if="loading">Loading...</h1>
		</o-field>
		<span></span>
		<div class="admin">
			<div class="portSummary" id="adminEditFavAssets" :key="tableKey">
				<h2>Remove Asset from Port. Summary</h2>

				<o-table :data="favoriteAssets">
					<o-table-column label="Ticker" field="ticker" sortable v-slot="props">
						{{ props.row.Ticker }}
					</o-table-column>
					<o-table-column label="" :field="tableOverview" sortable>
						<template v-slot="props">
							<o-button class="deleteButton" @click="removeFavoriteAsset(props.row.Ticker)">
								Remove
							</o-button>
						</template>
					</o-table-column>
				</o-table>
			</div>
			<div class="add-Asset-Section">
				<h2>Add Asset to Port. Summary</h2>
				<o-field>
					<o-autocomplete
						v-model="assetToAdd"
						placeholder="ticker"
						open-on-focus
						:data="filteredNonfavoriteAssets"
						field="asset"
						@select="wait" />
				</o-field>
				<o-button @click="addFavoriteAsset">Add Asset</o-button>
			</div>
			<div class="file-upload-section">
				<h2>Manual Database Upload</h2>
				<div class="file-upload-section2">
					<o-button :style="{ backgroundColor: accountFile ? ' #6ba543' : '' }">
						<div v-if="!accountFile">Select Accounts File</div>
						<div v-if="accountFile">{{ accountFile?.name }}</div>
						<input type="file" @change="onAccountFileSelected" class="inputButton" />
					</o-button>
					<o-button :style="{ backgroundColor: productFile ? ' #6ba543' : '' }">
						<div v-if="!productFile">Select Products File</div>
						<div v-if="productFile">{{ productFile?.name }}</div>
						<input type="file" @change="onProductFileSelected" class="inputButton" />
					</o-button>

					<o-button :style="{ backgroundColor: file28571 ? ' #6ba543' : '' }">
						<div v-if="!file28571">Select 28571 File</div>
						<div v-if="file28571">{{ file28571?.name }}</div>
						<input type="file" @change="on28571FileSelected" class="inputButton" />
					</o-button>
					<br />

					<div class="upload-button-container">
						<o-button v-if="!uploaded" @click="uploadFile">Upload Files To Database</o-button>
					</div>
					<br />
					<br />
					<div class="instructions">
						<h3>
							1. Go to
							<a
								href="https://api.cloud.orionadvisor.com/orionconnectapp/#/reports/dataqueries/querybuilder">
								https://api.cloud.orionadvisor.com/orionconnectapp/#/reports/dataqueries/querybuilder
							</a>
						</h3>
						<h3>
							2. Run 'Accounts'. Change the 'Run For' dropdown to 'Database', and choose a file type
							of Excel. Click Run
						</h3>
						<h3>
							3. Run 'Products'. Change the 'Run For' dropdown to 'Database', change the 'As of
							Date' to today, and choose a file type of Excel. Click Run
						</h3>
						<h3>
							4. Go to
							<a href="https://api.cloud.orionadvisor.com/orionconnectapp/#/reports/dataqueries">
								https://api.cloud.orionadvisor.com/orionconnectapp/#/reports/dataqueries
							</a>
						</h3>
						<h3>5. Run '28571'. Change 'Run To' file type to Excel. Click Run</h3>
						<h3>
							6. Add today's downloaded files to the correct input boxes above, and click Upload.
						</h3>
						<h3>
							The Upload process of overwriting the database will begin and should be completed in a
							few minutes.
						</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { useRoute } from "vue-router";
	import axios from "axios";
	import { defineComponent } from "vue";
	const $http = inject("$http") as any; //for posts and such

	export default defineComponent({
		props: { hhID: { type: Number } },
		emits: [],
		data() {
			return {
				// fileInput: null
				// accountFile: null as File | null,
				// productFile: null as File | null,
				// file28571: null as File | null,
				// uploaded: false
			};
		},
		methods: {
			// onAccountFileSelected(event: Event) {
			// 	const target = event.target as HTMLInputElement;
			// 	const files = target.files;
			// 	if (files) {
			// 		this.accountFile = files[0];
			// 	}
			// },
			// onProductFileSelected(event: Event) {
			// 	const target = event.target as HTMLInputElement;
			// 	const files = target.files;
			// 	if (files) {
			// 		this.productFile = files[0];
			// 	}
			// },
			// on28571FileSelected(event: Event) {
			// 	const target = event.target as HTMLInputElement;
			// 	const files = target.files;
			// 	if (files) {
			// 		this.file28571 = files[0];
			// 	}
			// },
			// async uploadFile() {
			// 	// const toast = inject("$toast") as any;
			// 	if (!this.accountFile) {
			// 		alert("Please select an Account file");
			// 		return;
			// 	}
			// 	if (!this.productFile) {
			// 		alert("Please select a Product file");
			// 		return;
			// 	}
			// 	if (!this.file28571) {
			// 		alert("Please select a 28571 file");
			// 		return;
			// 	}
			// 	if (!this.accountFile.name.endsWith(".xlsx")) {
			// 		alert("Please only use .xlsx type files for the Account File");
			// 	}
			// 	if (!this.productFile.name.endsWith(".xlsx")) {
			// 		alert("Please only use .xlsx type files for the Product File");
			// 	}
			// 	if (!this.file28571.name.endsWith(".xlsx")) {
			// 		alert("Please only use .xlsx type files for the 28571 Query File");
			// 	}
			// 	// this.uploaded = true;
			// 	const formData = new FormData();
			// 	formData.append("productFile", this.productFile);
			// 	formData.append("accountFile", this.accountFile);
			// 	formData.append("file28571", this.file28571);
			// 	formData.append("key", "DSFjiozxjoiSFDIOph78787372");
			// 	try {
			// 		console.log("file", formData, this.productFile);
			// 		// alert("Your Files Are Being Uploaded to the Database");
			// 		console.log("TOAST: ", this.$toast);
			// 		this.$toast.open({
			// 			message: "Your Files Are Being Uploaded to the Database",
			// 			type: "is-info",
			// 			position: "is-top",
			// 			duration: 3000
			// 		});
			// 		const response = await axios.post("/api/Orion/ManualFileUpload", formData, {
			// 			headers: {
			// 				"Content-Type": "multipart/form-data"
			// 			}
			// 		});
			// 		console.log("File uploaded successfully", response);
			// 	} catch (error) {
			// 		console.error("Error uploading file", error);
			// 	}
			// }
		},
		setup(props) {
			document.title = "Portfolio Summary";

			const $http = inject("$http") as any; //for posts and such
			const $moment = inject("$moment") as any; //date math
			const $success = inject("$success") as any; // $success("It worked!")
			const $error = inject("$error") as any;
			const $route = useRoute();
			const optionalFirstColumn = ref("pe");
			const optionalSecondColumn = ref("quality");

			const loading = ref(false);
			const profile = ref("");
			const date = ref($moment.now());

			const overview = ref();
			const favoriteAssets = ref();
			const nonfavoriteAssets = ref([]);
			const assetToAdd = ref();
			const combinedPortSummary = ref();
			const tableOverview = ref();
			const tableKey = ref(0);
			const file1 = ref(null);
			const file2 = ref(null);
			const file3 = ref(null);

			const accountFile = ref(null as File | null); //: null as File | null,
			const productFile = ref(null as File | null);
			const file28571 = ref(null as File | null);
			const uploaded = ref(false);

			const onAccountFileSelected = (event: Event) => {
				const target = event.target as HTMLInputElement;
				const files = target.files;
				if (files) {
					accountFile.value = files[0];
				}
			};
			const onProductFileSelected = (event: Event) => {
				const target = event.target as HTMLInputElement;
				const files = target.files;
				if (files) {
					productFile.value = files[0];
				}
			};
			const on28571FileSelected = (event: Event) => {
				const target = event.target as HTMLInputElement;
				const files = target.files;
				if (files) {
					file28571.value = files[0];
				}
			};
			const uploadFile = async () => {
				if (!accountFile.value) {
					alert("Please select an Account file");
					return;
				}
				if (!productFile.value) {
					alert("Please select a Product file");
					return;
				}
				if (!file28571.value) {
					alert("Please select a 28571 file");
					return;
				}
				if (!accountFile.value.name.endsWith(".xlsx")) {
					alert("Please only use .xlsx type files for the Account File");
				}
				if (!productFile.value.name.endsWith(".xlsx")) {
					alert("Please only use .xlsx type files for the Product File");
				}
				if (!file28571.value.name.endsWith(".xlsx")) {
					alert("Please only use .xlsx type files for the 28571 Query File");
				}

				uploaded.value = true;
				const formData = new FormData();
				formData.append("productFile", productFile.value);
				formData.append("accountFile", accountFile.value);
				formData.append("file28571", file28571.value);
				formData.append("key", "DSFjiozxjoiSFDIOph78787372");

				try {
					console.log("file", formData, productFile.value);
					// alert("Your Files Are Being Uploaded to the Database");

					$success("Your Files Are Being Uploaded to the Database", { duration: 10000 });

					const response = await axios.post("/api/Orion/ManualFileUpload", formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					});

					console.log("File uploaded successfully", response);
				} catch (error) {
					console.error("Error uploading file", error);
				}
			};

			// const fileInput = ref(null);
			const fileInput = ref<HTMLInputElement | null>(null);

			const trim = (val, num = 0) => {
				if (num === 0) return Math.floor(val);

				const d = Math.pow(10, num);
				const e = Math.floor(val * d) / d;
				return e.toFixed(num);
				// return Math.floor(val * d) / d;
			};

			const asDollars = (val, num = 0) => {
				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
					minimumFractionDigits: num,
					maximumFractionDigits: num
				}).format(val);
			};

			const formatDate = (dateTime) => {
				const date = new Date(dateTime);
				const year = date.getFullYear();

				let month = (1 + date.getMonth()).toString();
				month = month.length > 1 ? month : "0" + month;

				let day = date.getDate().toString();
				day = day.length > 1 ? day : "0" + day;

				return month + "/" + day + "/" + year;
			};
			const formatOptionalColumn = (header, value) => {
				if (value == null) {
					return "-";
				} else if (header == "pe_ntm") {
					return trim(value, 1);
				} else if (header == "pe") {
					return trim(value, 1);
				} else if (header == "range") {
					return trim(value * 100) + "%";
				} else if (header == "return5y") {
					return trim(value * 100) + "%";
				} else if (header == "return3y") {
					return trim(value * 100) + "%";
				} else if (header == "return1y") {
					return trim(value * 100) + "%";
				} else if (header == "returnYTD") {
					return trim(value * 100) + "%";
				} else if (header == "quality") {
					return trim(value * 100) + "%";
				} else if (header == "releaseDate") {
					return formatDate(value);
				} else if (header == "kpi") {
					return trim(value, 2) + "%";
				} else if (header == "momentum") {
					return trim(value * 100) + "%";
				} else if (header == "sigma") {
					return trim(value * 100, 1);
				} else return value;
			};

			// summary = {totalT, sleeveT, modelT, legacyRestrictedT, outsideT, totalNT, sleeveNT, modelNT, legacyRestrictedNT, outsideNT};

			const portSummaryOverview = async () => {
				loading.value = true;
				try {
					// var response = await $http.get("/api/asset/portSummaryOverview");
					var response = await $http.get("/api/asset/favoriteassets");
					var nonFavresponse = await $http.get("/api/asset/nonfavoriteassets");

					console.log(response);

					if (response.status == 200) {
						console.log(response);

						favoriteAssets.value = response.data;
					} else {
						$error("Could not load Admin");
					}
					if (nonFavresponse.status == 200) {
						console.log(nonFavresponse);

						nonfavoriteAssets.value = nonFavresponse.data;
					} else {
						$error("Could not load Admin");
					}
					loading.value = false;
					loading.value = false;
				} catch (err) {
					console.log("Failed to get Favorite Assets");
					$error("400 error: Failed to Load Favorite Assets");
					loading.value = false;
				}
			};

			const removeFavoriteAsset = async (ticker) => {
				console.log(ticker);

				var response = await $http.post("/api/asset/removeFavoriteAsset", { ticker: ticker });
				console.log(response, response.value);
				if (response.status == 200) {
					$success("Successfully Removed");
					favoriteAssets.value = response.data;
				} else {
					$error("Error: We had trouble Removing the Asset");
				}
			};

			const wait = async (option) => {
				return;
				// if (!option) return;
				// console.log("Hit: ", assetToAdd.value);
			};
			const addFavoriteAsset = async (option) => {
				if (!option) return;
				console.log("Hit: ", assetToAdd.value);
				var response = await $http.post("/api/asset/addFavoriteAsset", {
					ticker: assetToAdd.value.toUpperCase()
				});
				console.log(response, response.value);

				if (response.status == 200) {
					$success("Successfully Added");
					favoriteAssets.value = response.data;
					tableKey.value = Math.random();
				} else {
					$error("Error: We had trouble Adding the Asset");
				}
			};
			const filteredNonfavoriteAssets = computed(() => {
				console.log("OBJECT", nonfavoriteAssets, nonfavoriteAssets.value);
				return nonfavoriteAssets.value
					.filter((option: any) => {
						if (option && option.asset && assetToAdd.value)
							return (
								option.asset.toString().toLowerCase().indexOf(assetToAdd.value.toLowerCase()) >= 0
							);
					})
					.slice(0, 20);
			});

			onMounted(() => {
				portSummaryOverview();
			});

			return {
				loading,
				date,
				overview,
				favoriteAssets,
				nonfavoriteAssets,
				assetToAdd,
				combinedPortSummary,
				tableOverview,
				optionalFirstColumn,
				optionalSecondColumn,
				tableKey,
				filteredNonfavoriteAssets,
				trim,
				asDollars,
				formatDate,
				formatOptionalColumn,
				portSummaryOverview,
				removeFavoriteAsset,
				addFavoriteAsset,
				wait,
				file1,
				file2,
				file3,
				fileInput,
				accountFile,
				productFile,
				file28571,
				uploaded,
				onAccountFileSelected,
				onProductFileSelected,
				on28571FileSelected,
				uploadFile
			};
		}
	});
</script>
<style lang="scss" >
	.admin {
		// display: flex;
		// flex-direction: row;
		// flex-wrap: wrap;
		// gap: 2rem;
		display: flex;
		flex-wrap: wrap;
	}
	.admin {
		width: auto;
		min-width: 0;
	}
	.admin tr td,
	.admin tr td {
		&:nth-child(1) {
			text-align: left !important;
			font-weight: 600;
			background-color: #6ba543;
			color: white;
		}
	}
	.admin th {
		&:nth-child(1) {
			color: white;
			border-top-right-radius: 0.3rem;
		}
		&:nth-child(2) {
			background-color: #f9f9f9;
		}
	}
	.admin tr {
		&:nth-child(1) {
			& td {
				&:nth-child(1) {
					border-top-left-radius: 0.3rem;
				}
			}
		}
	}

	#adminEditFavAssets {
		// margin: 0;
		tr td,
		th {
			&:nth-child(1) {
				max-width: 12rem;
			}
			&:nth-child(2) {
				width: 8rem;
				padding-right: 1rem;
			}

			text-align: left;
		}
		tr {
			&:nth-child(odd) td {
				&:nth-child(1) {
					background-color: #7eb15b;
				}
			}
			&:nth-child(even) td {
				&:nth-child(1) {
					background-color: #6ca543;
				}
			}
		}
	}

	.deleteButton {
		.button {
			background-color: rgb(186, 26, 26);
		}
	}

	.admin {
		display: flex;
		flex-wrap: wrap;
	}
	.portSummary {
		flex-basis: 25%;
		margin-right: 1rem;
	}
	.add-Asset-Section {
		flex-basis: 30%;
		margin-right: 3rem;
	}

	.file-upload-section {
		flex-basis: 33%;
	}
	.file-upload-section2 {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}

	.inputButton {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		cursor: pointer;

		white-space: nowrap;
	}
	.upload-button-container {
		margin-top: 1rem;
	}
</style>


