<template>
    <div style="height: 800px; width: 100%;">
        <line-chart id="research-primary" style="z-index:5" :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins">Failed to load.</line-chart>
    </div>
</template>
<script>
    import { ref, inject, computed, onMounted } from "vue";
    import lineChart from "@/components/research/line-chart.vue";
    import functions from "@/components/research/functions.js";
    import { toFont } from 'chart.js/helpers';
    import externalTooltip from "@/components/research/externalTooltip.js";
    import { min } from "moment";

    export default {
        components: {
            lineChart
        },
        props: {
            startDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date(new Date().getFullYear() - 1, 1, 1)
                }
            },
            endDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date()
                }
            },
            customOptions: {
                type: Object,
                default: () => {
                    return { avgRange: 12, hideTooltip: false }
                }
            },
            rawData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            transactions: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            plugins: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        setup: (props, context) => {
            const moment = inject("$moment");

            function getFirstNonNullIndex(...arrays) {
                const minLength = Math.min(...arrays.map(arr => arr.length));

                for (let i = 0; i < minLength; i++) {
                    const nonNullValues = arrays.map(arr => arr[i]);
                    if (nonNullValues.every(val => val !== null && val !== undefined)) {
                        return i;
                    }
                }
                return -1; //No correlated non-null ordinal
            }

            const tooltip_hidden = computed(() => { return props.customOptions?.hideTooltip == true; });

            const data = computed(() => {
                if (!props.startDate || !props.endDate) return props.rawData;
                else {
                    return props.rawData.filter(d => props.startDate <= moment(d["related_date"], "YYYY-MM-DD").toDate() && moment(d["related_date"], "YYYY-MM-DD").toDate() <= props.endDate);
                }
            });


            const transactionPoints = computed(() => {
                var minSplitFactor = Math.min(...data.value.map(d => d["price_1"]));
                return props.transactions.map(t => {
                    var periodIndex = data.value.findIndex(d => d["period"] == t["period"]);
                    var size = t.size || 0;
                    var action = t.action.toLowerCase();
                    var color = 'rgba(255, 0, 0, 0.25)'
                    var borderColor = color;

                    var intensity = 255;
                    if (size == 0)
                        intensity = 255;
                    else if (size == 1)
                        intensity = 100;
                    else if (size == 2)
                        intensity = 175;
                    else if (size == 3)
                        intensity = 250;

                    if (action == "buy") {
                        borderColor = 'rgba(0,255,0,1)';
                        color = 'rgba(0, ' + intensity + ', 0, 1)'
                    }
                    else if (action == "sell") {
                        borderColor = 'rgba(255,0,0,1)';
                        color = 'rgba(' + intensity + ', 0, 0, 1)'
                    }

                    if (size == 0)
                        color = 'rgba(0, 0, 0, 0)';
                    else
                        borderColor = color;

                    return {
                        type: 'point',
                        yScaleID: 'y_right',
                        xValue: periodIndex,
                        radius: 6,
                        yValue: functions.price(data.value[periodIndex], minSplitFactor), //data.value[data.value.findIndex(d => d["period"] == t["period"])]["price"], //data.value.find(d => d["related_date"] == t["related_date"])["price"
                        backgroundColor: color,
                        borderColor: borderColor,
                        borderWidth: 2,
                    }
                });
            });

            const chartOptions = computed(() => {
                var minSplitFactor = Math.min(...data.value.map(d => d["price_1"]));
                return {
                    z_index: 0,
                    name: "Top Chart",
                    responsive: true,
                    maintainAspectRatio: false,
                    //aspectRatio: 2 / 1,
                    backgroundColor: "rgba(0,0,0,0)",
                    scales: {
                        x: {
                            ticks: {
                                callback: function (val, index) {
                                    if (!val) return '';
                                    const parts = this.getLabelForValue(val).split('-');
                                    const d = new Date(parts[0], parts[1] - 1, parts[2]);
                                    const format = moment(d).format("YYYY");
                                    return format;
                                }
                            }
                        },
                        y_left: {
                            afterUpdate: function (scale) {
                                scale.chart.scales.y_left.start = scale.chart.scales.y_right.start / 15;
                                scale.chart.scales.y_left.end = scale.chart.scales.y_right.end / 15;

                                scale.chart.scales.y_left.min = scale.chart.scales.y_right.min / 15;
                                scale.chart.scales.y_left.max = scale.chart.scales.y_right.max / 15;
                                scale.chart.scales.y_left.ticks = scale.chart.scales.y_right.ticks.filter(v => v.value % 15 == 0).map(v => { v.value /= 15; v.label = (v.value).toString(); return v; });

                                return;
                            },
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = 50;
                            },
                            type: 'logarithmic',
                            display: true,
                            position: 'left',
                        },
                        y_right: {
                            type: 'logarithmic',
                            display: true,
                            position: 'right',
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = 100;
                            },
                            min: 0,
                            suggestedMax: Math.max(...data.value.map(d => functions.price(d, minSplitFactor))) * 1.25,
                        }
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false
                    },
                    plugins: {
                        tooltip: {
                            enabled: false,
                            position: 'nearest',
                            hideTooltip: props.customOptions?.hideTooltip == true,
                            external: externalTooltip
                        },
                        annotation: {
                            annotations: transactionPoints.value
                        }
                    },
                    layout: {
                        autoPadding: false
                    }
                }
            });



            const chartData = computed(() => {
                var minSplitFactor = Math.min(...data.value.map(d => d["price_1"]));
                return {
                    labels: data.value.map(d => d["related_date"]),
                    datasets: [
                        {
                            label: "Price",
                            fill: false,
                            borderColor: "blue",
                            data: data.value.map(d => functions.price(d, minSplitFactor)),
                            yAxisID: 'y_right',
                            pointRadius: 1
                        },
                        {
                            label: "EPS",
                            fill: false,
                            borderColor: "magenta",
                            data: data.value.map(d => functions.trailing_earnings(d, minSplitFactor)),
                            yAxisID: 'y_left',
                            pointRadius: 1
                        },
                        {
                            label: "EPS Estimate",
                            fill: false,
                            borderColor: "purple",
                            data: data.value.map(d => functions.one_year_forward_earnings(d, minSplitFactor)),
                            yAxisID: 'y_left',
                            pointRadius: 1
                        },
                        {
                            label: "EPS 2 yr. Estimate",
                            fill: false,
                            borderColor: "violet",
                            data: data.value.map(d => functions.two_year_forward_earnings(d, minSplitFactor)),
                            yAxisID: 'y_left',
                            pointRadius: 1
                        },
                        {
                            label: "Dividend",
                            fill: false,
                            borderColor: "lightgreen",
                            data: data.value.map(d => functions.dividend(d, minSplitFactor)),
                            yAxisID: 'y_left',
                            pointRadius: 1
                        },
                        {
                            label: "Avg Price",
                            fill: false,
                            borderColor: "lightblue",
                            borderDash: [5, 5],
                            data: data.value.map(d => functions.average_price_behind(d, data.value, Number.parseInt(props.customOptions.avgRange), minSplitFactor)),
                            yAxisID: 'y_right',
                            pointRadius: 1
                        }
                    ]
                }
            });

            return {
                chartOptions,
                chartData,
                transactionPoints
            }
        }
    }
</script>
