import shared from "@/components/research/shared.js";

//sharePlugin requires the chart to have an id.
const verticalLine = {
    id: "vertical-line",
    afterDraw: chart => {
        if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.y_right;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
            ctx.borderDash = [5, 5];
            ctx.stroke();
            ctx.restore();
        }
    }
}
export {
    verticalLine
}
