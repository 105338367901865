import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Template from "@/pages/template.vue";
import Dashboard from "@/pages/dashboard.vue"
import Assets from "@/pages/assets.vue"
import HouseHolds from "@/pages/households.vue"
import Asset from "@/pages/asset.vue"
import SingleHouseHold from "@/pages/singleHouseHold.vue"
import PortSummary from "@/pages/portSummary.vue"
import Admin from "@/pages/admin.vue"
import Research from "@/pages/research.vue";




const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'template',
        component: Template,
        children: [
            {
                path: '',
                // name: 'portSummary',
                component: PortSummary,
                meta: {
                    title: 'Port Summary'
                }
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    title: 'Dashboard'
                }
            },
            {
                path: '/assets',
                name: 'assets',
                component: Assets,
                meta: {
                    title: 'Assets'
                }
            },
            {
                path: '/households',
                name: 'households',
                component: HouseHolds,
                meta: {
                    title: 'HouseHolds'
                }
            },
            {
                path: '/asset/:ticker?',
                name: 'asset',
                component: Asset,
                meta: {
                    title: 'Asset'
                }
            },
            {
                path: '/singleHouseHold/:hhID?',
                name: 'singleHouseHold',
                component: SingleHouseHold,
                meta: {
                    title: 'Single HouseHold'
                }
            },
            {
                path: '/portSummary',
                name: 'portSummary',
                component: PortSummary,
                meta: {
                    title: 'Port Summary'
                }
            },
            {
                path: '/admin',
                name: 'admin',
                component: Admin,
                meta: {
                    title: 'Admin'
                }
            },
            {
                path: '/research',
                name: 'research',
                component: Research,
                meta: {
                    title: "Research"
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '@/pages/login.vue')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
// router.beforeEach((to, from, next) => {
//     if (to.meta && to.meta.title) {
//         // document.title = to.meta.title;
//         document.title = 'Prentiss Smith and Company';
//     }
//     else {
//         document.title = 'Prentiss Smith and Company';
//     }
// })

export default router
