<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">{{ label }}</p>
        </header>
        <div class="card-content">
            <o-field label="Note">
                <o-input type="textarea" v-model="noteCopy"></o-input>
            </o-field>
            <em v-if="noteTimeStamp" class="metadata">
                <template v-if="noteUser">Edited by {{ noteUser }} <br /></template>
                <time>{{ noteTimeStamp }}</time>
            </em>

        </div>
        <div class="card-footer">
            <o-button @click="Save" v-if="dirty" variant="primary" class="card-footer-item" :disabled="saving">Save</o-button>
            <o-button @click="Revert" v-if="dirty" variant="warning" class="card-footer-item" :disabled="saving">Revert</o-button>
            <o-button @click="Close" class="card-footer-item" :disabled="saving">Close</o-button>
        </div>
    </div>
</template>

<script>
import { ref, computed, inject } from "vue";

export default {
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    emits: ["update", "close"],
    setup(props, { emit }) {
        const $http = inject("$http");
        const $success = inject("$success");

        const note = computed(() => {
            return props.params.Note?.Note || "";
        });

        const name = computed(() => {
            return props.params.AccountName || "";
        });

        const noteTimeStamp = computed(() => {
            return props.params.Note?.NoteTimeStamp || "";
        });

        const noteUser = computed(() => {
            return props.params.Note?.NoteUser || "[Not logged]";
        });

        const isAsset = computed(() => {
            if (props.params.AssetTicker) return true;
            return false;
        });

        const label = computed(() => {
            let str = name.value;
            if (isAsset.value) {
                str += " - Asset " + props.params.AssetTicker;
            } else {
                str += " - Account";
            }
            return str;
        });

        const noteCopy = ref("");
        noteCopy.value = note.value;

        const dirty = computed(() => {
            return note.value !== noteCopy.value;
        });

        const saving = ref(false);
        const Save = () => {
            saving.value = true;

            if (isAsset.value) {
                const requestBody = {
                    AccountID: props.params.Note.AccountID,
                    AssetTicker: props.params.AssetTicker,
                    Note: noteCopy.value
                };
                const response = $http({
                    method: "POST",
                    url: "/api/asset/addAssetNote",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: requestBody
                }).then((resp) => {
                    if (resp.status === 200) {
                        $success("Note saved successfully!");
                        saving.value = false;
                        emit("update", resp.data);
                        emit("close");
                    }
                });
            }
            // const requestBody = {
            // 	selectedHouseholdIDs: selectedHouseholdIDs,
            // 	trades: tradeArray
            // 	// trades: trades.value
            // };
            // console.log("request body", requestBody);

            // // Make API POST request here
            // var response = $http({
            // 	method: "POST",
            // 	url: "/api/gdrive/initiateLiveTrade",
            // 	headers: {
            // 		"Content-Type": "application/json"
            // 	},
            // 	data: requestBody
            // })
            // 	.then(
            // 		function successCallback(response) {
            // 			// if (response && response.data ) {
            // 			if (response.status == 200) {
            // 				window.open("https://docs.google.com/spreadsheets/d/" + response.data, "_blank");
            // 			} else {
            // 				console.error("Invalid response object:", response);
            // 				$error("Something Went Wrong... Make sure you have selected HouseHolds and Buys");
            // 			}
            // 			loading.value = false;
            // 		},
            // 		function errorCallback(response) {
            // 			$error("Something Went Wrong... Make sure you have selected HouseHolds and Buys");
            // 			loading.value = false;
            // 		}
            // 	)
            // 	.catch(function errorCallback(error) {
            // 		console.error("Error occurred:", error);
            // 		$error("Error: Please Make sure you have at least one HouseHold Selected");
            // 		loading.value = false;
            // 	});
        };

        const Revert = () => {
            noteCopy.value = note.value;
        };

        const Close = () => {
            emit("close");
        }

        // setup(props, context) {
        //console.log(props.asset);

        // const submitForm = () => {
        // 	// context.emit(“submit", myArray);
        // 	emit("submit", trades.value);
        // 	emit("close");
        // 	// console.log(trades);
        // };

        return {
            noteCopy,
            noteTimeStamp,
            noteUser,
            label,
            dirty,
            saving,
            Save,
            Revert,
            Close
        };
    }
};
</script>



<style scoped>
.tradeForm {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.trade {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.heading {
    font-size: 24px;
    margin-top: 0;
}

.inputGroup {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tradeLabel {
    flex: 0 0 100px;
    font-size: 16px;
    margin-right: 2px;
}

.tradeInput {
    flex: 1;
    font-size: 12px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    margin-left: -20px;
}

.tradeInput {
    flex: 1;
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    width: calc(33.33% - 10px);
    margin-right: 10px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.heading {
    font-size: 24px;
    margin-top: 0;
}

.removeButton {
    font-size: 16px;
    color: #ff0000;
    background: none;
    border: none;
    cursor: pointer;
}

.removeButton {
    font-size: 16px;
    color: #ff0000;
    background: none;
    border: none;
    cursor: pointer;
}

.trade__remove {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.removeButton {
    font-size: 16px;
    font-weight: bold;
    color: #f44336;
    background: none;
    border: none;
    cursor: pointer;
}

.removeButton:hover {
    color: #d32f2f;
}

.removeButton:focus {
    outline: none;
}

em.metadata {
    display: block;
    margin-top: 1em;
    margin-bottom: -0.8em;
}
</style>
