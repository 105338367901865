import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "asset-note-button"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_o_button = _resolveComponent("o-button")!

  return (!$setup.isAsset || $props.params.node.level === 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_o_button, {
          onClick: $setup.ClickHandler,
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, { icon: $setup.buttonIcon }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["onClick"]),
        ($setup.note)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.note), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}