import shared from "@/components/research/shared.js";

//sharePlugin requires the chart to have an id.
const sharePlugin = {
    id: "share-plugin",
    beforeInit: function (chart) {
        shared.AddChart(chart);
    } 
}
export {
    sharePlugin
}
