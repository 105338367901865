<template>
    <div id="nav" class="columns">
        <!--<div class="column">
            <router-link to="/">Home</router-link>
        </div>
        <div class="column">
            <router-link to="/login">Login</router-link>
        </div>-->
    </div>
    <router-view />
</template>

<script>
import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
export default {
    setup() {
        const global = getCurrentInstance()?.appContext.config.globalProperties;
        const $http = inject("$http");

        const $route = useRoute();

        var refreshPromises = [];
        var refreshing = false;
        $http.interceptors.response.use(
            (resp) => {
                return resp;
            },
            async (err) => {
                if (err.response?.status == 401) {
                    // console.log("TEST");
                    var url = "";
                    if (process.env.NODE_ENV == "development")
                        url = process.env.VUE_APP_API + "/api/user/google/login?returnUrl=" + $route.path;
                    //for dev
                    else url = "/api/user/google/login?returnUrl=" + $route.path; //for prod
                    // console.log(process.env.VUE_APP_API);
                    window.location.href = url;
                }
            }
        );

        return {};
    }
};
</script>
