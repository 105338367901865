<template>
	<div class="app-main">
		<nav class="app-navbar navbar">
			<div class="navbar-logo navbar-brand">
				<img class="navbar-item" src="@/assets/ps-logo.png" alt="ps-logo" />
			</div>
			<div class="app-directory-links navbar-menu">
				<div class="navbar-start">
					<router-link class="navbar-item" :to="{ name: 'portSummary' }">Portfolio</router-link>
					<router-link class="navbar-item" :to="{ name: 'assets' }">Assets</router-link>
					<router-link class="navbar-item" :to="{ name: 'households' }">Households</router-link>
					<a class="navbar-item" href="/research">Research</a>
					<router-link class="navbar-item" :to="{ name: 'admin' }">Admin</router-link>
				</div>
			</div>
			<div style="min-width: 300px">
				<o-autocomplete v-model="search" rounded icon="search" placeholder="Search" clearable :data="filteredData" field="text" @focus="() => (search = '')" @select="sendToRoute">
					<template #empty>No results found</template>
				</o-autocomplete>
			</div>
		</nav>
		<main class="app-body">
			<div style="max-width: 300px"></div>
			<router-view />
		</main>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { useRoute, useRouter } from "vue-router";
	export default {
		props: {},
		emits: [],
		setup() {
			const route = useRoute();
			const router = useRouter();
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $moment = inject("$moment");
			const $success = inject("$success");
			const $error = inject("$error");

			const search = ref("");
			const selected = ref(null);

			const data = ref([]);

			const sendToRoute = (option) => {
				if (!option) return;
				if (option.type == "household")
					router.push({ name: "singleHouseHold", params: { hhID: option.id } });
				else if (option.type == "asset")
					router.push({ name: "asset", params: { ticker: option.id } });
			};

			const getOmni = async () => {
				var response = await $http.get("/api/account/omni");

				if (response && (response.status == 200 && response.data.length != 0)) {
					data.value = response.data;
				} else {
					$error("Could not load omni search");
				}
			};

			getOmni();

			const filteredData = computed(() => {
				return data.value
					.filter(
						(option) => option.text.toString().toLowerCase().indexOf(search.value.toLowerCase()) >= 0
					)
					.slice(0, 20);
			});

			return { filteredData, search, router, sendToRoute };
		}
	};
</script>
<style lang="scss" scoped>
	@import "@/assets/css/globalVars.scss";

	.app-main {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: "nav" "content";
		background-color: #fdfdfdd8;
		height: 100%;
		overflow: hidden;

		& main {
			overflow-y: auto;
		}
	}

	.app-navbar {
		@include flex-row;
		align-items: center;
		grid-area: nav;
		//width: 18rem;
		padding: 0 1rem;
		background-color: white;
		border-bottom: 1px solid rgba(79, 105, 74, 0.2);
	}

	.navbar-logo {
		//height: 20rem;
		width: 8rem;
		padding: 0.5rem;
		// padding-left: 0.5rem;
		// padding-top: 0rem;
		// padding-bottom: 0rem;
	}

	.app-directory-links {
		//background-color: red;
		@include flex-column;
		align-items: flex-start;
		//margin-top: 3rem;
		margin-left: 1rem;
		gap: 0.2rem;
		padding: 0.3rem;

		&>a {
			display: block;
			padding: 0.1rem 0.3rem;
			//background-color: pink;
			font-size: 1em;
			//font-weight: 500;

			color: $ps-green-dark;

			&:hover {
				color: $ps-green;
			}
		}
	}

	.app-body {
		padding-top: 0.75rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		// padding-bottom: 0.75rem;

		// padding: 3rem;
	}
</style>
