<template>
    <div>
        <div>
            <h1 class="houseHoldHeader" v-if="displayedTable === 'Households'">Households Summary</h1>
            <h1 class="houseHoldHeader" v-if="displayedTable === 'Accounts'">Accounts Summary</h1>
            <!-- {{ "selectedHouseholdIDs: " + selectedHouseholdIDs }} -->

            <!-- <o-button @click="initiateLiveTrade">Initiate Live Trade</o-button> -->
            <o-button type="button" @click="openModal">Initiate Live Trade</o-button>
            <h1 class="houseHoldHeader" v-if="loading">Loading...</h1>
            <o-field class="houseHoldHeader" id="accountToggle">
                <o-switch v-model="displayedTable" true-value="Households" false-value="Accounts" @click="refresh">
                    {{ displayedTable }}
                </o-switch>
            </o-field>
        </div>
        <!-- <br /> -->

        <div v-show="displayedTable === 'Households'">
            <ag-grid-vue class="ag-theme-alpine" :rowData="houseHoldList" domLayout="500" style="height: 55rem" :defaultColDef="defaultColDef" :columnDefs="hhColumnDefs.value" @grid-ready="onGridHHReady"></ag-grid-vue>
        </div>
        <div v-show="displayedTable === 'Accounts'">
            <ag-grid-vue class="ag-theme-alpine" :rowData="accountList" domLayout="500" style="height: 55rem" :defaultColDef="defaultColDef" :columnDefs="accountColumnDefs.value" @grid-ready="onGridAccountReady"></ag-grid-vue>
        </div>
    </div>
</template>
<!-- <script lang="ts"> -->
<script>
import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";
// import { useProgrammatic } from "@oruga-ui/oruga-next";
import TradeModal from "@/components/tradeModal.vue";
export default {
    props: {},
    emits: [],
    components: {
        AgGridVue
        // useProgrammatic,
        // TradeModal
    },
    setup() {
        const $oruga = inject("$oruga");
        document.title = "HouseHold Summary";

        const showModal = ref(false);
        // const trades = ref([]);
        const trades = ref([
            {
                ticker: "",
                percent: "",
                price: ""
            }
        ]);

        // const addTrade = () => {
        // 	const newTrade = {
        // 		symbol: this.symbol,
        // 		price: this.price,
        // 		quantity: this.quantity
        // 	};
        // 	trades.value.push(newTrade);
        // 	this.symbol = "";
        // 	this.price = "";
        // 	this.quantity = "";
        // 	showModal.value = false;
        // 	initiateLiveTrade(trades.value);
        // };

        const gridHHApi = ref(); // Optional - for accessing Grid's API
        const gridAccountApi = ref(); // Optional - for accessing Grid's API

        const isLiveTradeModalOpen = ref(false);
        const ticker = ref("");
        const percent = ref(0);
        const price = ref(0);

        const onGridHHReady = (params) => {
            gridHHApi.value = params.api;
            // console.log("ONGRidHHREADY", houseHoldHeaders.value);
            // params.columnApi.autoSizeAllColumns();
            gridHHApi.value.refreshHeader();
        };
        const onGridAccountReady = (params) => {
            gridAccountApi.value = params.api;
            // console.log("Here", account/Headers.value);
            // params.columnApi.autoSizeAllColumns();
            gridAccountApi.value.refreshHeader();
        };

        const defaultColDef = {
            resizible: true,
            sortable: true,
            width: 90,
            filter: "agTextColumnFilter",
            floatingFilter: true
        };
        const formatDate = (dateTime) => {
            const date = new Date(dateTime);
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };
        const dateFormat = function (params) {
            if (params.value == null) return "";
            const date = new Date(params.value);
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };

        const currencyFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 0);
        };

        const currencyCentsFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 2);
        };

        const integerFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 0);
        };

        const singleDecimalFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 1);
        };

        const numberFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 2);
        };

        const percentFormatter = function (params) {
            if (params.value == null) return "0%";
            params.value *= 100;
            return formatNumber(params, 0) + "%";
        };
        const percentFormatterSubtract1 = function (params) {
            if (params.value == null) return "0%";
            params.value = (1 - params.value) * 100;
            return formatNumber(params, 0) + "%";
        };
        const percentFormatterHideNull = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 0) + "%";
        };
        const percentFormatterWithoutPercent = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 1);
        };

        const formatNumber = function (params, precision = 0) {
            if (params.value == null) return "";
            return parseFloat(params.value)
                .toFixed(precision)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        };

        const rowData = reactive({});
        const hhColumnDefs = reactive({
            value: [
                {
                    headerName: "Name",
                    children: [
                        {
                            field: "fullName",
                            filter: "agTextColumnFilter",
                            width: 300,
                            maxWidth: 300,
                            resizable: true,
                            cellRenderer: (params) => {
                                return (
                                    '<a href = "/singleHouseHold/' +
                                    params.data.hhid +
                                    '"  target="_blank"> ' +
                                    params.value +
                                    " </a>"
                                );
                            }
                        },
                        { field: "lastName", columnGroupShow: "open", width: 200 }
                    ]
                },
                {
                    children: [
                        {
                            field: "marketValue",
                            headerName: "Market Value\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "Market Value\n" + asDollars(params.api.houseHoldHeaders.marketValueMedian)
                                    );
                                else return "Market Value\n" + " ";
                            },
                            width: 140,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "equitySleeve",
                            headerName: "Eq. Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "Eq. Sleeve\n" +
                                        trim(params.api.houseHoldHeaders.equitySleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "Eq. Sleeve\n" + " ";
                            },
                            width: 127,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "equity",
                            headerName: "Equity\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return "Equity\n" + trim(params.api.houseHoldHeaders.equityMedian * 100, 1) + "%";
                                else return "Equity\n" + " ";
                            },
                            width: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        }
                    ]
                },
                {
                    openByDefault: true,
                    headerName: "Model",
                    children: [
                        {
                            field: "model",
                            headerName: "Model\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return "Model\n" + trim(params.api.houseHoldHeaders.modelMedian * 100, 1) + "%";
                                else return "Model\n" + " ";
                            },
                            width: 110,
                            // columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "modelEquitySleeve",
                            headerName: "Eq./Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "Eq./Sleeve\n" +
                                        trim(params.api.houseHoldHeaders.modelEquitySleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "Eq./Sleeve\n" + " ";
                            },
                            width: 119,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "outside",
                            headerName: "Outside\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "Outside\n" + trim(params.api.houseHoldHeaders.outsideMedian * 100, 1) + "%"
                                    );
                                else return "Outside\n" + " ";
                            },
                            width: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterHideNull,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "legacy",
                            headerName: "Legacy\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return "Legacy\n" + trim(params.api.houseHoldHeaders.legacyMedian * 100, 1) + "%";
                                else return "Legacy\n" + " ";
                            },
                            width: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterHideNull,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "restricted",
                            headerName: "Restricted\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "Restricted\n" +
                                        trim(params.api.houseHoldHeaders.restrictedMedian * 100, 1) +
                                        "%"
                                    );
                                else return "Restricted\n" + " ";
                            },
                            width: 118,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterHideNull,
                            type: "rightAligned",
                            resizable: true
                        }
                    ]
                },
                {
                    openByDefault: true,
                    children: [
                        {
                            field: "equitySleeve",
                            headerName: "FI Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "FI Sleeve\n" +
                                        trim((1 - params.api.houseHoldHeaders.equitySleeveMedian) * 100, 1) +
                                        "%"
                                    );
                                else return "FI Sleeve\n" + " ";
                            },
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterSubtract1,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "bonds",
                            headerName: "FI\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return "FI\n" + trim(params.api.houseHoldHeaders.bondsMedian * 100, 1) + "%";
                                else return "FI\n" + " ";
                            },
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 110
                        },
                        {
                            field: "bondsEquitySleeve",
                            headerName: "FI/Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return (
                                        "FI/Sleeve\n" +
                                        trim(params.api.houseHoldHeaders.bondsEquitySleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "FI/Sleeve\n" + " ";
                            },
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 119
                        },
                        {
                            field: "cash",
                            headerName: "Cash\n",
                            headerValueGetter: (params) => {
                                if (params.api.houseHoldHeaders)
                                    return "Cash\n" + trim(params.api.houseHoldHeaders.cashMedian * 100, 1) + "%";
                                else return "Cash\n" + " ";
                            },
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 110
                        },
                        {
                            headerName: "Live Trade",
                            field: "selected",
                            width: 100,
                            columnGroupShow: "open",
                            cellRenderer: function (params) {
                                const checkbox = document.createElement("input");
                                checkbox.type = "checkbox";
                                checkbox.checked = params.data.selected;
                                checkbox.addEventListener("click", function (event) {
                                    const selected = event.target.checked;
                                    params.data.selected = selected;
                                    if (selected) {
                                        // addHousehold(params.data.hhid);
                                        addHousehold(params.data.hhid);
                                    } else {
                                        removeHousehold(params.data.hhid);
                                    }
                                });
                                return checkbox;
                            }
                        }
                    ]
                },
                {
                    openByDefault: true,
                    children: [
                        {
                            field: "StartingBalance",
                            headerName: "1/1 Starting Balance\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        // {
                        // 	field: "EndingBalance",
                        // 	headerName: "As Of Balance\n",
                        // 	columnGroupShow: "open",
                        // 	width: 110,
                        // 	filter: "agNumberColumnFilter",
                        // 	valueFormatter: currencyFormatter,
                        // 	type: "rightAligned",
                        // 	resizable: true
                        // },
                        {
                            field: "NetWithdrawalDeposit",
                            headerName: "Net W/D\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "WithdrawalDepositPercent",
                            headerName: "W/D %\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },

                        // TODO: [PS-126] Get calculations for HH NetFeePerformance and EqOnlyGrossPerf
                        // {
                        // 	field: "NetFeePerformance",
                        // 	headerName: "Net Fee Performance\n",
                        // 	columnGroupShow: "open",
                        // 	width: 110,
                        // 	filter: "agNumberColumnFilter",
                        // 	valueFormatter: percentFormatter,
                        // 	type: "rightAligned",
                        // 	resizable: true
                        // },
                        // {
                        // 	field: "EqOnlyGrossPerf",
                        // 	headerName: "Eq Only Gross Perf\n",
                        // 	columnGroupShow: "open",
                        // 	width: 110,
                        // 	filter: "agNumberColumnFilter",
                        // 	valueFormatter: percentFormatter,
                        // 	type: "rightAligned",
                        // 	resizable: true
                        // },
                        {
                            field: "RealizedGainsAndLosses",
                            headerName: "Realized G/L\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                    ]
                }
            ]
        });
        const accountColumnDefs = reactive({
            value: [
                {
                    headerName: "Name",
                    children: [
                        {
                            field: "fullName",
                            filter: "agTextColumnFilter",
                            width: 300,
                            maxWidth: 300,
                            resizable: true,
                            cellRenderer: (params) => {
                                return (
                                    '<a href = "/singleHouseHold/' +
                                    params.data.hhid +
                                    '"  target="_blank"> ' +
                                    params.value +
                                    " </a>"
                                );
                            }
                        },
                        { field: "lastName", columnGroupShow: "open", width: 200 }
                    ]
                },
                {
                    children: [
                        {
                            field: "marketValue",
                            headerName: "Market Value\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Market Value\n" + asDollars(params.api.accountHeaders.marketValueMedian);
                                else return "Market Value\n" + " ";
                            },
                            width: 140,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "equitySleeve",
                            headerName: "Eq. Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return (
                                        "Eq. Sleeve\n" +
                                        trim(params.api.accountHeaders.equitySleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "Eq. Sleeve\n" + " ";
                            },
                            width: 127,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "equity",
                            headerName: "Equity\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Equity\n" + trim(params.api.accountHeaders.equityMedian * 100, 1) + "%";
                                else return "Equity\n" + " ";
                            },
                            width: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        }
                    ]
                },
                {
                    openByDefault: true,
                    headerName: "Model",
                    children: [
                        {
                            field: "model",
                            headerName: "Model\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Model\n" + trim(params.api.accountHeaders.modelMedian * 100, 1) + "%";
                                else return "Model\n" + " ";
                            },
                            width: 110,
                            // columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "modelEquitySleeve",
                            headerName: "Eq./Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return (
                                        "Eq./Sleeve\n" +
                                        trim(params.api.accountHeaders.modelEquitySleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "Eq./Sleeve\n" + " ";
                            },
                            width: 119,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "outside",
                            headerName: "Outside\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Outside\n" + trim(params.api.accountHeaders.outsideMedian * 100, 1) + "%";
                                else return "Outside\n" + " ";
                            },
                            width: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterHideNull,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "legacy",
                            headerName: "Legacy\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Legacy\n" + trim(params.api.accountHeaders.legacyMedian * 100, 1) + "%";
                                else return "Legacy\n" + " ";
                            },
                            width: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterHideNull,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "restricted",
                            headerName: "Restricted\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return (
                                        "Restricted\n" + trim(params.api.accountHeaders.restrictedMedian * 100, 1) + "%"
                                    );
                                else return "Restricted\n" + " ";
                            },
                            width: 118,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterHideNull,
                            type: "rightAligned",
                            resizable: true
                        }
                    ]
                },
                {
                    openByDefault: true,
                    children: [
                        {
                            field: "equitySleeve",
                            headerName: "FI Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return (
                                        "FI Sleeve\n" +
                                        trim((1 - params.api.accountHeaders.equitySleeveMedian) * 100, 1) +
                                        "%"
                                    );
                                else return "FI Sleeve\n" + " ";
                            },
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterSubtract1,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "bonds",
                            headerName: "FI\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "FI\n" + trim(params.api.accountHeaders.bondsMedian * 100, 1) + "%";
                                else return "FI\n" + " ";
                            },
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 110
                        },
                        {
                            field: "bondsEquitySleeve",
                            headerName: "FI/Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return (
                                        "FI/Sleeve\n" +
                                        trim(params.api.accountHeaders.bondsEquitySleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "FI/Sleeve\n" + " ";
                            },
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 119
                        },
                        {
                            field: "bondWeight",
                            headerName: "Maturity\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Maturity\n" + trim(params.api.accountHeaders.bondWeightMedian, 1);
                                else return "Maturity\n" + " ";
                            },
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: singleDecimalFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 120
                        },
                        {
                            field: "cash",
                            headerName: "Cash\n",
                            headerValueGetter: (params) => {
                                if (params.api.accountHeaders)
                                    return "Cash\n" + trim(params.api.accountHeaders.cashMedian * 100, 1) + "%";
                                else return "Cash\n" + " ";
                            },
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 110
                        }
                    ]
                },
                {
                    openByDefault: true,
                    children: [
                        {
                            field: "StartingBalance",
                            headerName: "1/1 Starting Balance\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        // {
                        // 	field: "EndingBalance",
                        // 	headerName: "As Of Balance\n",
                        // 	columnGroupShow: "open",
                        // 	width: 110,
                        // 	filter: "agNumberColumnFilter",
                        // 	valueFormatter: currencyFormatter,
                        // 	type: "rightAligned",
                        // 	resizable: true
                        // },
                        {
                            field: "NetWithdrawalDeposit",
                            headerName: "Net W/D\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "WithdrawalDepositPercent",
                            headerName: "W/D %\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "NetFeePerformance",
                            headerName: "Return\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "EqOnlyGrossPerf",
                            headerName: "Eq Only\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "RealizedGainsAndLosses",
                            headerName: "Realized G/L\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                    ]
                }
            ]
        });

        const $http = inject("$http"); //for posts and such
        const $moment = inject("$moment"); //date math
        const $success = inject("$success"); // $success("It worked!")
        const $error = inject("$error");

        const loading = ref(false);
        const profile = ref("");
        const date = ref($moment.now());
        const houseHoldList = ref();
        const houseHoldHeaders = ref();
        const accountList = ref();
        const accountHeaders = ref();
        const assetSummary = ref();
        const optionalFirstColumn = ref("pe");
        const optionalSecondColumn = ref("quality");
        const displayedTable = ref("Households");
        const selectedHouseholdIDs = [];

        function addHousehold(id) {
            console.log("Adding household with id", id);

            selectedHouseholdIDs.push(id);
            console.log("selectedHouseholdIDs", selectedHouseholdIDs);
        }

        function removeHousehold(id) {
            const index = selectedHouseholdIDs.indexOf(id);
            if (index !== -1) {
                selectedHouseholdIDs.splice(index, 1);
            }
        }

        const openModal = () => {
            const modalInstance = $oruga.modal.open({
                component: TradeModal,
                trapFocus: true,
                props: {
                    trades: trades.value
                },
                events: {
                    submit: (tradeArray) => {
                        // console.log("Got It ", tradeArray);
                        loading.value = true;

                        initiateLiveTrade(tradeArray);
                        // loading.value = false;
                    }
                }
            });
        };
        const initiateLiveTrade = (tradeArray) => {
            loading.value = true;
            const requestBody = {
                selectedHouseholdIDs: selectedHouseholdIDs,
                trades: tradeArray
                // trades: trades.value
            };
            console.log("request body", requestBody);

            // Make API POST request here
            var response = $http({
                method: "POST",
                url: "/api/gdrive/initiateLiveTrade",
                headers: {
                    "Content-Type": "application/json"
                },
                data: requestBody
            })
                .then(
                    function successCallback(response) {
                        // if (response && response.data ) {
                        if (response.status == 200) {
                            window.open("https://docs.google.com/spreadsheets/d/" + response.data, "_blank");
                        } else {
                            console.error("Invalid response object:", response);
                            $error("Something Went Wrong... Make sure you have selected HouseHolds and Buys");
                        }
                        loading.value = false;
                    },
                    function errorCallback(response) {
                        $error("Something Went Wrong... Make sure you have selected HouseHolds and Buys");
                        loading.value = false;
                    }
                )
                .catch(function errorCallback(error) {
                    console.error("Error occurred:", error);
                    $error("Error: Please Make sure you have at least one HouseHold Selected");
                    loading.value = false;
                });
        };

        profile.value = "hi";

        const trim = (val, num = 0) => {
            if (num === 0) return Math.floor(val);

            const d = Math.pow(10, num);
            const e = Math.floor(val * d) / d;
            return e.toFixed(num);
        };

        const asDollars = (val, num = 0) => {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: num,
                maximumFractionDigits: num
            }).format(val);
        };

        const formatOptionalColumn = (header, value) => {
            if (value == null) {
                return "-";
            } else if (header == "pe_ntm") {
                return trim(value, 1);
            } else if (header == "pe") {
                return trim(value, 1);
            } else if (header == "range") {
                return trim(value * 100) + "%";
            } else if (header == "return5y") {
                return trim(value * 100) + "%";
            } else if (header == "return3y") {
                return trim(value * 100) + "%";
            } else if (header == "return1y") {
                return trim(value * 100) + "%";
            } else if (header == "returnYTD") {
                return trim(value * 100) + "%";
            } else if (header == "quality") {
                return trim(value * 100) + "%";
            } else if (header == "releaseDate") {
                return formatDate(value);
            } else if (header == "kpi") {
                return trim(value, 2) + "%";
            } else if (header == "momentum") {
                return trim(value * 100) + "%";
            } else if (header == "sigma") {
                return trim(value * 100, 1);
            } else return value;
        };

        const hhSummary = async () => {
            loading.value = true;
            try {
                var hhResponse = await $http.get("/api/account/houseHoldSummary");

                if ((hhResponse.status = 200 && hhResponse.data.length != 0)) {
                    $success("HouseHold Summary");
                    //console.log(hhResponse);
                    houseHoldList.value = hhResponse.data.allHHs;
                    houseHoldHeaders.value = hhResponse.data.accountTableHeaders;
                    gridHHApi.value.houseHoldHeaders = houseHoldHeaders.value;
                    gridHHApi.value.refreshHeader();
                } else {
                    $error("Could Not Load HouseHold Summary");
                }
                loading.value = false;
            } catch (err) {
                //console.log("Failed to get Report");
                $error("400 error: Failed to Load HouseHold Summary");
                loading.value = false;
            }
        };
        const accountSummary = async () => {
            loading.value = true;
            try {
                var accountResponse = await $http.get("/api/account/accountsSummary");

                if ((accountResponse.status = 200 && accountResponse.data.length != 0)) {
                    // $success("Account Summary");
                    accountList.value = accountResponse.data.allAccounts;
                    accountHeaders.value = accountResponse.data.accountTableHeaders;
                    gridAccountApi.value.accountHeaders = accountHeaders.value;
                    gridAccountApi.value.refreshHeader();
                } else {
                    $error("Could Not Load HouseHold Summary");
                }
                loading.value = false;
            } catch (err) {
                //console.log("Failed to get Report");
                $error("400 error: Failed to Load HouseHold Summary");
                loading.value = false;
            }
        };

        const refresh = async () => {
            gridHHApi.value.refreshHeader();
            gridAccountApi.value.refreshHeader();
            // console.log("REFRESH HIT");
        };

        const showHH = async () => {
            //take to new page
        };

        onMounted(() => {
            hhSummary();
            accountSummary();
        });
        return {
            loading,
            profile,
            date,
            displayedTable,
            houseHoldList,
            houseHoldHeaders,
            accountList,
            accountHeaders,
            optionalFirstColumn,
            optionalSecondColumn,
            trim,
            asDollars,
            formatDate,
            dateFormat,
            formatOptionalColumn,
            hhSummary,
            accountSummary,
            showHH,
            hhColumnDefs,
            accountColumnDefs,
            defaultColDef,
            selectedHouseholdIDs,
            isLiveTradeModalOpen,
            ticker,
            percent,
            price,
            refresh,
            onGridHHReady,
            onGridAccountReady,
            initiateLiveTrade,
            showModal,
            openModal,
            trades
        };
    }
};
</script>
<style lang="scss">
.houseHoldHeader {
    display: inline-block;
}

#accountToggle {
    float: right;
}
</style>
