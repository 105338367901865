import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "topOfAsset" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "topOfAssetLists"
}
const _hoisted_4 = {
  key: 0,
  class: "assetReport"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_field = _resolveComponent("o-field")!
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_o_table = _resolveComponent("o-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, "Asset Summary: " + _toDisplayString($setup.displayTicker), 1),
        _createVNode(_component_o_field, { grouped: "" }, {
          default: _withCtx(() => [
            ($setup.loading)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Loading..."))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      ($setup.assetSummary)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_ag_grid_vue, {
        class: "ag-theme-alpine",
        rowData: $setup.assetList,
        domLayout: "500",
        style: {"height":"55rem"},
        defaultColDef: $setup.defaultColDef,
        columnDefs: $setup.columnsDef.value,
        onGridReady: $setup.onGridReady,
        groupIncludeFooter: true,
        groupIncludeTotalFooter: true
      }, null, 8, ["rowData", "defaultColDef", "columnDefs", "onGridReady"])
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_o_table, {
            data: $setup.assetList,
            height: "50rem",
            "debounce-search": 1000
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_table_column, {
                label: 'Last Name',
                field: "lastName",
                sortable: "",
                searchable: ""
              }, {
                default: _withCtx((props) => [
                  _createVNode(_component_router_link, {
                    to: { name: 'singleHouseHold', params: { hhID: props.row.householdID } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.row.lastName), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 1
              }),
              _createVNode(_component_o_table_column, {
                label: "Account",
                field: "accountName",
                sortable: "",
                searchable: "",
                class: "assetReportColumn"
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.accountName), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_o_table_column, {
                "aria-label": " ",
                field: "accountName"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_o_table_column, {
                label: 'Assets' + ($setup.assetSummary != null ? '\n' + $setup.asDollars($setup.assetSummary?.assetsSummation) : '')
                ,
                field: "assets",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.asDollars(props.row.assets)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: "Type",
                field: "type",
                sortable: "",
                searchable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.type), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_o_table_column, {
                label: "Broker",
                field: "broker",
                sortable: "",
                searchable: "",
                width: "2px"
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.replaceWithCodes(props.row.broker)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_o_table_column, {
                label: 'Sleeve ' +
                    ($setup.assetSummary != null ? '\n' + $setup.trim($setup.assetSummary.sleeveMedian * 100, 1) + '%' : '')
                ,
                field: "sleeve",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.trim(props.row.sleeve * 100)) + "% ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Total ' +
                    ($setup.assetSummary != null ? '\n' + $setup.trim($setup.assetSummary.totalMedian * 100, 0) + '%' : '')
                ,
                field: "total",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.trim(props.row.total * 100)) + "% ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Model ' +
                    ($setup.assetSummary != null ? '\n' + $setup.trim($setup.assetSummary.modelMedian * 100, 0) + '%' : '')
                ,
                field: "model",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.trim(props.row.model * 100)) + "% ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Shares ' + '\n' + ($setup.assetSummary != null ? $setup.assetSummary?.unitsSummation : ''),
                field: "units",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.units), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: '#Lots ' + '\n' + ($setup.assetSummary != null ? $setup.assetSummary?.numLotsSummation : ''),
                field: "numLots",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.numLots), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: '% ' +
                    ($setup.assetSummary != null ? '\n' + $setup.trim($setup.assetSummary.percentMedian * 100, 1) + '%' : '')
                ,
                field: "percent",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.trim(props.row.percent * 100, 1)) + "% ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Gain ' +
                    '\n' +
                    ($setup.assetSummary != null ? $setup.trim($setup.assetSummary.gainMedian * 100, 0) + '%' : '')
                ,
                field: "gain",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.trim(props.row.gain * 100)) + "% ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: '% (Adj.) ' +
                    '\n' +
                    ($setup.assetSummary != null ? $setup.trim($setup.assetSummary.percentOverSleeveMedian * 100, 1) + '%' : '')
                ,
                field: "percentOverSleeve",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.trim(props.row.percentOverSleeve * 100, 1)) + "% ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'ID' + '\n' + ($setup.assetSummary != null ? $setup.assetSummary?.idMode : ''),
                field: "id",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString(props.row.id), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Value ' + '\n' + ($setup.assetSummary != null ? $setup.asDollars($setup.assetSummary?.valueSummation) : '')
                ,
                field: "value",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.asDollars(props.row.value)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Basis ' + '\n' + ($setup.assetSummary != null ? $setup.asDollars($setup.assetSummary?.basisSummation) : '')
                ,
                field: "basis",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.asDollars(props.row.basis)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: 'Date ' + '\n' + ($setup.assetSummary != null ? $setup.formatDate($setup.assetSummary?.dateMode) : ''),
                field: "tradeDate",
                sortable: ""
              }, {
                default: _withCtx((props) => [
                  _createTextVNode(_toDisplayString($setup.formatDate(props.row.tradeDate)), 1)
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data"])
        ]))
      : _createCommentVNode("", true)
  ]))
}