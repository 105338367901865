var shared_charts = [];

function CleanCharts() {
    shared_charts = shared_charts.filter(chart => chart && chart.canvas && chart.canvas.id);
    return true;
}
function OrderByZIndex() {
    shared_charts.sort((a, b) => (a.config.options?.z_index || 0) > (b.config.options?.z_index || 0) ? 1 : -1);
}
function GetCharts() {
    return shared_charts.slice(0);
}
function ClearCharts() {
    shared_charts = [];
    return true;
}
function AddChart(chart) {
    CleanCharts();
    if (chart && chart.canvas && !shared_charts.some(c => c.canvas?.id == chart.canvas?.id)) {
        shared_charts.push(chart);
        OrderByZIndex();
        return true;
    }
    return false;
}

export default {
    GetCharts,
    AddChart,
    ClearCharts
}
