<template>
    <div v-if="!isAsset || params.node.level === 1" class="asset-note-button">
        <o-button @click="ClickHandler" size="small"><fa-icon :icon="buttonIcon"></fa-icon></o-button>
        <p v-if="note">{{ note }}</p>
    </div>
</template>

<script lang="ts">
import { inject, ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import AssetNoteModal from "@/components/assetNoteModal.vue";
export default {
    props: {
        params: {
            type: Object,
            default: () => { return {}; }
        }
    },
    emits: ["update"],
    setup(props, { emit }) {

        const $oruga = inject("$oruga") as Record<string, any>;
        const OpenModal = (params) => {
            const modalInstance = $oruga.modal.open({
                component: AssetNoteModal,
                trapFocus: true,
                props: {
                    params: params
                },
                events: {
                    update: (data) => {
                        var api = props.params.api;
                        var updatedData = props.params.node.aggData;
                        updatedData.NoteWithMetadata = data;
                        api.updateRowData({ update: [updatedData] })
                    }
                }
            });
        };

        const $route = useRoute();

        const isAsset = computed(() => {
            if (!(props.params.data?.accountName)) { //this is correct
                return true;
            }
            return false;
        });

        const ClickHandler = () => {
            let data: any = {};

            if (props.params.data) {
                //data.HouseholdID = $route.params.hhID;
                data.AssetTicker = props.params.data.AssetTicker;
                data.Note = props.params.data.notes;
                data.AccountName = props.params.data.accountName;
            } else if (props.params.node.level === 1) {
                //data.HouseholdID = $route.params.hhID;
                data.AssetTicker = props.params.node.aggData.AssetTicker;
                data.Note = props.params.node.aggData.NoteWithMetadata;
                data.AccountName = props.params.node.childrenAfterGroup[0].data.AccountName;
            } else {
                data = null;
            }

            OpenModal(data);
        };

        const note = computed(() => {
            return props.params.data?.Note || props.params.data?.notes || props.params.value?.Note;
        });

        const buttonIcon = computed(() => {
            if (note.value) {
                return "pencil";
            }
            return "plus";
        });

        return {
            isAsset,
            ClickHandler,
            note,
            buttonIcon
        }
    }
}
</script>
