<template>
    <div style="position: absolute; bottom: 35px; height: 200px; width: 100%;">
        <line-chart id="research-tertiary" style="z-index: 10" :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins">Failed to load.</line-chart>
    </div>
</template>
<script>
    import { ref, inject, computed, onMounted } from "vue";
    import lineChart from "@/components/research/line-chart.vue";
    import functions from "@/components/research/functions.js";
    import { toFont } from 'chart.js/helpers';
    import externalTooltip from "@/components/research/externalTooltip.js";
    import { max, min } from "moment";

    export default {
        components: {
            lineChart
        },
        props: {
            startDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date(new Date().getFullYear() - 1, 1, 1)
                }
            },
            endDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date()
                }
            },
            customOptions: {
                type: Object,
                default: () => {
                    return { avgRange: 12, hideTooltip: false }
                }
            },
            rawData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            deals: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            plugins: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        setup: (props, context) => {
            const moment = inject("$moment");

            const dealPoints = computed(() => {
                var minPeriod = Math.min(...data.value.map(d => d["period"]));
                var maxPeriod = Math.max(...data.value.map(d => d["period"]));
                var periods = maxPeriod - minPeriod;

                function splitNotes(notes) {
                    if (!notes) return "";
                    var tooLong = 60;
                    return notes.split(". ").flatMap(x => {
                        if (x.length > tooLong) {
                            var index = x.indexOf(' ', tooLong);
                            // If there is no space after the 80th character, index will be -1
                            if (index === -1) {
                                return [x.endsWith('.') ? x : x + '.']; // Ensure it ends with a period
                            }
                            return [
                                x.slice(0, index),
                                x.slice(index + 1).endsWith('.') ? x.slice(index + 1) : x.slice(index + 1) + '.'
                            ];
                        }
                        return x.endsWith('.') ? x : x +'.'; // Add the period back to the sentence
                    });
                }

                var maxMarketCap = Math.max(...data.value.map(d => d["cap"]));

                return props.deals.flatMap(x => {

                    var type = ''
                    if (x["acquirer_ticker"] == ticker.value)
                        type = 'acquirer';
                    else if (x["target_ticket"] == ticker.value)
                        type = 'target';
                    else
                        type = 'seller';

                    var dealType = x["deal_type"];

                    var period = (x["complete_period"] || x["announcement_period"]);
                    if (type == 'target')
                        period = (x["announcement_period"] || x["complete_period"]);

                    var periodIndex = data.value.findIndex(d => d["period"] == period);

                    var marketCap = data.value[periodIndex]?.cap || 0;

                    if (marketCap == 0) return [];

                    var percentage = x["total_value"] / marketCap;
                    var minSize = 5;
                    var maxSize = 60;
                    var size = minSize;
                    if (dealType.toLowerCase() == 'spin-off') {
                        if (type == 'target')
                            size = minSize;
                        else
                            size = Math.min(Math.max(x["total_value"] * maxSize, minSize), maxSize);
                    }
                    else
                        size = Math.min(Math.max(percentage * maxSize, minSize), maxSize);


                    var color = 'rgba(0,0,0,0.5)';
                    if (x["deal_status"]?.toLowerCase() != 'completed')
                        color = 'rgba(0,0,0,0.5)';
                    else if (type == 'acquirer')
                        color = 'rgba(255,0,0,0.5)';
                    else
                        color = 'rgba(0,255,0,0.5)';

                    var xAdjust = 0;

                    if (period < minPeriod + (periods * .4))
                        xAdjust = 200;
                    else if (period > maxPeriod - (periods * .4))
                        xAdjust = -200;

                    var yAdjust = -50;
                    if (marketCap > maxMarketCap * .6) {
                        yAdjust = 32;
                    }


                    var label = {
                        id: 'l' + periodIndex,
                        display: false,
                        type: 'label',
                        yScaleID: 'y_right',
                        xValue: periodIndex,
                        xAdjust: xAdjust,
                        yValue: marketCap,
                        yAdjust: yAdjust,
                        callout: {
                            display: true
                        },
                        borderColor: color,
                        backgroundColor: 'white',
                        borderWidth: 1,
                        font: { size: 10 },
                        textAlign: 'left',
                        content: splitNotes(x["notes"]),
                        z: 10,
                        click(element, event) {
                            label.display = !label.display;
                            event.chart.update();

                            return true; // force update
                        }
                    };

                    var point = {
                        id: 'p' + periodIndex,
                        type: 'point',
                        yScaleID: 'y_right',
                        xValue: periodIndex,
                        yValue: data.value[periodIndex].cap,
                        radius: size,
                        backgroundColor: color,
                        borderColor: color,
                        click(element, event) {
                            label.display = !label.display;
                            event.chart.update();

                            return true; // force update
                        }
                    };


                    return [point, label];
                });
            });

            const ticker = computed(() => {
                return props.rawData.length > 0 ? props.rawData[0].psc_ticker : "";
            });

            const data = computed(() => {
                if (!props.startDate || !props.endDate) return props.rawData;
                else {
                    return props.rawData.filter(d => props.startDate <= moment(d["related_date"], "YYYY-MM-DD").toDate() && moment(d["related_date"], "YYYY-MM-DD").toDate() <= props.endDate);
                }
            });

            const chartOptions = computed(() => {
                return {
                    z_index: 1,
                    name: "Middle Chart",
                    responsive: true,
                    maintainAspectRatio: false,
                    //aspectRatio: 1.5 / 1,
                    scales: {
                        x: {
                            display: false,
                            ticks: {
                                callback: function (val, index) {
                                    const parts = this.getLabelForValue(val).split('-');
                                    const d = new Date(parts[0], parts[1] - 1, parts[2]);
                                    const format = moment(d).format("YYYY");
                                    return format;
                                }
                            }
                        },
                        y_left: {
                            type: 'linear',
                            display: false,
                            position: 'left',
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = 50;
                            },
                        },
                        y_right: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = 100;
                            },
                            ticks: {
                                crossAlign: 'far',
                                maxTicksLimit: 5,
                            },
                            grid:
                            {
                                color: '#FEFEFE',
                            }


                            //min: 1,
                            //suggestedMax: 1000
                        }
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            display: false,
                            position: "bottom"
                        },
                        tooltip: {
                            enabled: false,
                            position: "nearest",
                            hideTooltip: props.customOptions?.hideTooltip == true,
                            external: externalTooltip
                        },
                        annotation: {
                            annotations: dealPoints.value
                        }
                    },
                    layout: {
                        autoPadding: false
                    }
                }
            });

            const chartData = computed(() => {
                return {
                    labels: data.value.map(d => d["related_date"]),
                    datasets: [
                        {
                            label: "Market Cap",
                            fill: false,
                            borderColor: "orange",
                            data: data.value.map(d => d["cap"] || null),
                            yAxisID: 'y_right',
                            pointRadius: 1
                        },
                        {
                            label: "Net Cash",
                            fill: true,
                            borderColor: "green",
                            backgroundColor: functions.getColor("green", 0.3),
                            data: data.value.map(d => functions.net_debt_neg(d)),
                            yAxisID: 'y_right',
                            pointRadius: 1
                        },
                        {
                            label: "Net Debt",
                            fill: true,
                            borderColor: "crimson",
                            backgroundColor: functions.getColor("crimson", 0.3),
                            data: data.value.map(d => functions.net_debt_pos(d)),
                            yAxisID: 'y_right',
                            pointRadius: 1
                        }
                    ]
                }
            });

            return {
                chartOptions,
                chartData
            }
        }
    }
</script>
