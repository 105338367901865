<template>
	<div>
		<!-- {{loading}} -->

		<!-- <h1>~~~~Content~~~~</h1> -->
		<!-- {{profile}} -->
		<h1>Dashboard</h1>
		<br />
		<br />

		<!-- <o-field grouped>
			<o-button @click="reportAsset">Create Report</o-button>
			<o-input placeholder="Ticker" maxlength="15" v-model="ticker"></o-input>
			<h1 v-if="loading">Loading...</h1>
		</o-field>

		<o-table :data="reportData">
			<o-table-column label="AID" field="accountID" v-slot="props">
				{{ props.row.accountID }}
			</o-table-column>
			<o-table-column label="HHID" field="householdID" v-slot="props">
				{{ props.row.householdID }}
			</o-table-column>
			<o-table-column label="Account" field="accountName" v-slot="props">
				{{ props.row.accountName }}
			</o-table-column>
			<o-table-column label="Tax Sens." field="taxSensitivity" v-slot="props">
				{{ props.row.taxSensitivity }}
			</o-table-column>
			<o-table-column label="Assets" field="assets" v-slot="props">
				{{ props.row.assets }}
			</o-table-column>
			<o-table-column label="Type" field="type" v-slot="props">{{ props.row.type }}</o-table-column>
			<o-table-column label="Broker" field="broker" v-slot="props">
				{{ props.row.broker }}
			</o-table-column>
			<o-table-column label="Code" field="code" v-slot="props">{{ props.row.code }}</o-table-column>
			<o-table-column label="Sleeve" field="sleeve" v-slot="props">
				{{ props.row.sleeve }}
			</o-table-column>
			<o-table-column label="Total" field="total" v-slot="props">
				{{ props.row.total }}
			</o-table-column>
			<o-table-column label="Model" field="model" v-slot="props">
				{{ props.row.model }}
			</o-table-column>
			<o-table-column label="Units" field="units" v-slot="props">
				{{ props.row.units }}
			</o-table-column>
			<o-table-column label="%" field="percent" v-slot="props">
				{{ props.row.percent }}
			</o-table-column>
			<o-table-column label="Gain" field="gain" v-slot="props">{{ props.row.gain }}</o-table-column>
			<o-table-column label="ID" field="id" v-slot="props">{{ props.row.id }}</o-table-column>
			<o-table-column label="Value" field="value" v-slot="props">
				{{ props.row.value }}
			</o-table-column>
			<o-table-column label="Basis" field="basis" v-slot="props">
				{{ props.row.basis }}
			</o-table-column>
			<o-table-column label="Date" field="tradeDate" v-slot="props">
				{{ props.row.tradeDate }}
			</o-table-column>
		</o-table> -->
	</div>
</template>
<script lang="ts">
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	export default {
		props: {},
		emits: [],
		setup() {
			// const global = getCurrentInstance()?.appContext.config.globalProperties; //probably avoiding to use global and will inject instead
			const $http = inject("$http") as any; //for posts and such
			const $moment = inject("$moment") as any; //date math
			const $success = inject("$success") as any; // $success("It worked!")
			const $error = inject("$error") as any;

			const loading = ref(false);
			const profile = ref("");
			const date = ref($moment.now());
			const reportData = ref();
			const ticker = ref();

			profile.value = "hi";

			const testFunction = () => {
				if (profile.value === "hi") $success("Yup");
				$http
					.get("/api/GCloud/reconcileLog")
					.then((resp) => {
						console.log(resp.data);
					})
					.catch((err) => err.response);
			};

			const testAsyncFunction = async () => {
				loading.value = true;
				try {
					// var resp = await $http.get("/api/GCloud/reconcileLog/iyjajjghwiskcmudf732alcid", {
					// 	Key: "iyjajjghwiskcmudf732alcid"
					// });
					var resp = await $http.get("/api/gcloud/reconcilelog/iyjajjghwiskcmudf732alcid");
					loading.value = false;
					$success("Yup");
					// console.log(resp.data);
					// profile.value = resp.data;
				} catch (err) {
					console.log("Catch test Async");
					// console.error(err);
				}
				// console.log("Loading = false");
				// console.log("Loading = false");
			};
			const reportAsset = async () => {
				loading.value = true;
				try {
					var response = await $http.get("/api/asset/report/" + ticker.value);
					if ((response.status = 200 && response.data.length != 0)) {
						$success("Showing " + ticker.value);
						console.log(response);
						reportData.value = response.data;
					} else {
						$error("Could Not Find the Ticker: " + ticker.value);
					}
					loading.value = false;
				} catch (err) {
					console.log("Failed to get Report");
					$error("400 error: Failed to get Report for: " + ticker.value);
					loading.value = false;
				}
			};

			return {
				loading,
				profile,
				date,
				reportData,
				ticker,
				testFunction,
				testAsyncFunction,
				reportAsset
			};
		}
	};
</script>
<style scoped>
</style>