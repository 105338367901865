<template>
	<div>
		<h1>Portfolio Summary: {{ $route.params.hhID }}</h1>
		<!-- <br /> -->
		<!-- {{ overview.totalT.toString() }} -->
		<!-- {{ portSummaryOverview }} -->
		<!-- <o-field grouped> -->
		<h1 v-if="loading">Loading...</h1>
		<!-- </o-field> -->
		<span></span>
		<div class="portSummaryTablesClass">
			<div class="portSummaryClass" id="portfolioOverview">
				<o-table :data="combinedPortSummary">
					<o-table-column label="" field="ProductCustomFieldStrategyGrouping" v-slot="props">
						{{ props.row.ProductCustomFieldStrategyGrouping }}
					</o-table-column>
					<o-table-column label="Taxable" field="taxable" v-slot="props">
						{{ asDollars(props.row.taxable) }}
					</o-table-column>
					<o-table-column label="" field="taxablePercent" v-slot="props">
						{{
							props.row.ProductCustomFieldStrategyGrouping !== "Total"
								? trim(props.row.taxablePercent * 100, 0) + "%"
								: ""
						}}
					</o-table-column>
					<o-table-column label="Non-Taxable" field="nonTaxable" v-slot="props">
						{{ asDollars(props.row.nonTaxable) }}
					</o-table-column>
					<o-table-column label="" field="nonTaxablePercent" v-slot="props">
						{{
							props.row.ProductCustomFieldStrategyGrouping !== "Total"
								? trim(props.row.nonTaxablePercent * 100, 0) + "%"
								: ""
						}}
					</o-table-column>
				</o-table>
			</div>

			<!-- <br><br> -->
			<!-- <div class="portSummary" id="portfolioOverview">
				<o-table :data="tableOverview">
					<o-table-column label="" field="name" v-slot="props">
						{{ props.row.name }}
					</o-table-column>
					<o-table-column label="Taxable" field="taxable" v-slot="props">
						{{ asDollars(props.row.taxable) }}
					</o-table-column>
					<o-table-column label="" field="percentT" v-slot="props">
						{{ props.row.name !== "Total" ? trim(props.row.percentT * 100, 0) + "%" : "" }}
					</o-table-column>
					<o-table-column label="NonTaxable" field="nontaxable" v-slot="props">
						{{ asDollars(props.row.nontaxable) }}
					</o-table-column>
					<o-table-column label="" field="percentNT" v-slot="props">
						{{ props.row.name !== "Total" ? trim(props.row.percentNT * 100, 0) + "%" : "" }}
					</o-table-column>
				</o-table>
			</div> -->
			<div class="portSummaryClass" id="assetOverview">
				<!-- <div class="favoriteAssets" id="assetOverview"> -->

				<o-table :data="favoriteAssets">
					<o-table-column label="" field="ticker" sortable v-slot="props">
						{{ props.row.ticker }}
					</o-table-column>
					<o-table-column label="Taxable" field="totalT" sortable v-slot="props">
						{{ props.row.totalT == null ? "-" : asDollars(props.row.totalT) }}
					</o-table-column>
					<o-table-column label="" field="percentT" sortable v-slot="props">
						{{
							props.row.name !== "Total"
								? props.row.percentT == null
									? "-"
									: trim(props.row.percentT * 100, 2) + "%"
								: ""
						}}
					</o-table-column>
					<o-table-column label="Non-Taxable" field="totalNT" sortable v-slot="props">
						{{ props.row.totalNT == null ? "-" : asDollars(props.row.totalNT) }}
					</o-table-column>
					<o-table-column label="" field="percentNT" sortable v-slot="props">
						{{
							props.row.name !== "Total"
								? props.row.percentNT == null
									? "-"
									: trim(props.row.percentNT * 100, 2) + "%"
								: ""
						}}
					</o-table-column>
					<o-table-column label="Price" field="price" sortable v-slot="props">
						{{ props.row.price == null ? "-" : asDollars(props.row.price, 2) }}
					</o-table-column>
					<o-table-column label="" field="changePercent" sortable v-slot="props">
						{{
							props.row.name !== "Total"
								? props.row.changePercent == null
									? "-"
									: trim(props.row.changePercent, 2) + "%"
								: ""
						}}
					</o-table-column>
					<o-table-column :label="optionalFirstColumn" :field="optionalFirstColumn" sortable>
						<template v-slot:header>
							<o-select v-model="optionalFirstColumn" placeholder="Select a name">
								<option value="pe_ntm">P/E (NTM)</option>
								<option value="pe">P/E</option>
								<option value="range">Range</option>
								<option value="return5y">Return5y</option>
								<option value="return3y">Return3y</option>
								<option value="return1y">Return1y</option>
								<option value="returnYTD">ReturnYTD</option>
								<option value="quality">Quality</option>
								<option value="releaseDate">Release Date</option>
								<option value="kpi">KPI</option>
								<option value="safety">Safety</option>
								<option value="finStrength">Fin. Strength</option>
								<option value="momentum">Momentum</option>
								<option value="sigma">Sigma</option>
							</o-select>
						</template>
						<template v-slot="props">
							{{ formatOptionalColumn(optionalFirstColumn, props.row[optionalFirstColumn]) }}
							<!-- {{ trim(props.row[optionalFirstColumn], 2) }} -->
						</template>
					</o-table-column>
					<o-table-column :label="optionalSecondColumn" :field="optionalSecondColumn" sortable>
						<template v-slot:header>
							<o-select v-model="optionalSecondColumn" placeholder="Select a name">
								<option value="pe_ntm">P/E (NTM)</option>
								<option value="pe">P/E</option>
								<option value="range">Range</option>
								<option value="return5y">Return5y</option>
								<option value="return3y">Return3y</option>
								<option value="return1y">Return1y</option>
								<option value="returnYTD">ReturnYTD</option>
								<option value="quality">Quality</option>
								<option value="releaseDate">Release Date</option>
								<option value="kpi">KPI</option>
								<option value="safety">Safety</option>
								<option value="finStrength">Fin. Strength</option>
								<option value="momentum">Momentum</option>
								<option value="sigma">Sigma</option>
							</o-select>
						</template>
						<template v-slot="props">
							{{ formatOptionalColumn(optionalSecondColumn, props.row[optionalSecondColumn]) }}
						</template>
					</o-table-column>
					<!-- <o-table-column label="P/E" field="pe" sortable v-slot="props">
						{{ props.row.pe == null ? "-" : trim(props.row.pe, 1) }}
					</o-table-column> -->
					<!-- <o-table-column label="Quality" field="quality" sortable v-slot="props">
						{{ props.row.quality == null ? "-" : trim(props.row.quality * 100, 0) + "%" }}
					</o-table-column> -->
				</o-table>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { useRoute } from "vue-router";
	export default {
		props: { hhID: { type: Number } },
		emits: [],
		setup(props) {
			document.title = "Portfolio Summary";

			const $http = inject("$http") as any; //for posts and such
			const $moment = inject("$moment") as any; //date math
			const $success = inject("$success") as any; // $success("It worked!")
			const $error = inject("$error") as any;
			const $route = useRoute();
			const optionalFirstColumn = ref("pe");
			const optionalSecondColumn = ref("quality");

			const loading = ref(false);
			const profile = ref("");
			const date = ref($moment.now());

			const overview = ref();
			const favoriteAssets = ref();
			const combinedPortSummary = ref();
			const tableOverview = ref();

			const trim = (val, num = 0) => {
				if (num === 0) return Math.floor(val);

				const d = Math.pow(10, num);
				const e = Math.floor(val * d) / d;
				return e.toFixed(num);
				// return Math.floor(val * d) / d;
			};

			const asDollars = (val, num = 0) => {
				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
					minimumFractionDigits: num,
					maximumFractionDigits: num
				}).format(val);
			};

			const formatDate = (dateTime) => {
				const date = new Date(dateTime);
				const year = date.getFullYear();

				let month = (1 + date.getMonth()).toString();
				month = month.length > 1 ? month : "0" + month;

				let day = date.getDate().toString();
				day = day.length > 1 ? day : "0" + day;

				return month + "/" + day + "/" + year;
			};
			const formatOptionalColumn = (header, value) => {
				if (value == null) {
					return "-";
				} else if (header == "pe_ntm") {
					return trim(value, 1);
				} else if (header == "pe") {
					return trim(value, 1);
				} else if (header == "range") {
					return trim(value * 100) + "%";
				} else if (header == "return5y") {
					return trim(value * 100) + "%";
				} else if (header == "return3y") {
					return trim(value * 100) + "%";
				} else if (header == "return1y") {
					return trim(value * 100) + "%";
				} else if (header == "returnYTD") {
					return trim(value * 100) + "%";
				} else if (header == "quality") {
					return trim(value * 100) + "%";
				} else if (header == "releaseDate") {
					return formatDate(value);
				} else if (header == "kpi") {
					return trim(value, 2) + "%";
				} else if (header == "momentum") {
					return trim(value * 100) + "%";
				} else if (header == "sigma") {
					return trim(value * 100, 1);
				} else return value;
			};

			// summary = {totalT, sleeveT, modelT, legacyRestrictedT, outsideT, totalNT, sleeveNT, modelNT, legacyRestrictedNT, outsideNT};

			const portSummaryOverview = async () => {
				loading.value = true;
				try {
					var response = await $http.get("/api/asset/portSummaryOverview");
					console.log(response);

					if (response.status == 200) {
						//&& portSummary.data.length != 0
						// $success("Port Summary Loaded");
						console.log(response);

						favoriteAssets.value = response.data.favoriteAssets;
						combinedPortSummary.value = response.data.combinedPortSummary;
						overview.value = response.data.portSummaryOverview; //can i just do .portSummaryOverview ?

						const total = {
							name: "Total",
							taxable: overview.value.totalT,
							nontaxable: overview.value.totalNT
						};
						const sleeve = {
							name: "Sleeve",
							taxable: overview.value.sleeveT,
							percentT: overview.value.sleeveT / overview.value.totalT,
							nontaxable: overview.value.sleeveNT,
							percentNT: overview.value.sleeveNT / overview.value.totalNT
						};
						const model = {
							name: "Model",
							taxable: overview.value.modelT,
							percentT: overview.value.modelT / overview.value.totalT,
							nontaxable: overview.value.modelNT,
							percentNT: overview.value.modelNT / overview.value.totalNT
						};
						const leagcyRestricted = {
							name: "Leg/Restr",
							taxable: overview.value.legacyRestrictedT,
							percentT: overview.value.legacyRestrictedT / overview.value.totalT,
							nontaxable: overview.value.legacyRestrictedNT,
							percentNT: overview.value.legacyRestrictedNT / overview.value.totalNT
						};
						const outside = {
							name: "Outside",
							taxable: overview.value.outsideT,
							percentT: overview.value.outsideT / overview.value.totalT,
							nontaxable: overview.value.outsideNT,
							percentNT: overview.value.outsideNT / overview.value.totalNT
						};

						const treasury01 = {
							name: "Treasury 0-1 yrs",
							taxable: overview.value.treasury01T,
							percentT: overview.value.treasury01T / overview.value.totalT,
							nontaxable: overview.value.treasury01NT,
							percentNT: overview.value.treasury01NT / overview.value.totalNT
						};
						const treasury12 = {
							name: "Treasury 1-2 yrs",
							taxable: overview.value.treasury12T,
							percentT: overview.value.treasury12T / overview.value.totalT,
							nontaxable: overview.value.treasury12NT,
							percentNT: overview.value.treasury12NT / overview.value.totalNT
						};
						const treasury35 = {
							name: "Treasury 3-5 yrs",
							taxable: overview.value.treasury35T,
							percentT: overview.value.treasury35T / overview.value.totalT,
							nontaxable: overview.value.treasury35NT,
							percentNT: overview.value.treasury35NT / overview.value.totalNT
						};
						const treasury69 = {
							name: "Treasury 6-9 yrs",
							taxable: overview.value.treasury69T,
							percentT: overview.value.treasury69T / overview.value.totalT,
							nontaxable: overview.value.treasury69NT,
							percentNT: overview.value.treasury69NT / overview.value.totalNT
						};
						const corporate01 = {
							name: "Corporate 0-1 yrs",
							taxable: overview.value.corporate01T,
							percentT: overview.value.corporate01T / overview.value.totalT,
							nontaxable: overview.value.corporate01NT,
							percentNT: overview.value.corporate01NT / overview.value.totalNT
						};
						const corporate12 = {
							name: "Corporate 1-2 yrs",
							taxable: overview.value.corporate12T,
							percentT: overview.value.corporate12T / overview.value.totalT,
							nontaxable: overview.value.corporate12NT,
							percentNT: overview.value.corporate12NT / overview.value.totalNT
						};
						const corporate35 = {
							name: "Corporate 3-5 yrs",
							taxable: overview.value.corporate35T,
							percentT: overview.value.corporate35T / overview.value.totalT,
							nontaxable: overview.value.corporate35NT,
							percentNT: overview.value.corporate35NT / overview.value.totalNT
						};
						const corporate69 = {
							name: "Corporate 6-9 yrs",
							taxable: overview.value.corporate69T,
							percentT: overview.value.corporate69T / overview.value.totalT,
							nontaxable: overview.value.corporate69NT,
							percentNT: overview.value.corporate69NT / overview.value.totalNT
						};
						const muni01 = {
							name: "Muni 0-1 yrs",
							taxable: overview.value.muni01T,
							percentT: overview.value.muni01T / overview.value.totalT,
							nontaxable: overview.value.muni01NT,
							percentNT: overview.value.muni01NT / overview.value.totalNT
						};
						const muni12 = {
							name: "Muni 1-2 yrs",
							taxable: overview.value.muni12T,
							percentT: overview.value.muni12T / overview.value.totalT,
							nontaxable: overview.value.muni12NT,
							percentNT: overview.value.muni12NT / overview.value.totalNT
						};
						const muni35 = {
							name: "Muni 3-5 yrs",
							taxable: overview.value.muni35T,
							percentT: overview.value.muni35T / overview.value.totalT,
							nontaxable: overview.value.muni35NT,
							percentNT: overview.value.muni35NT / overview.value.totalNT
						};
						const muni69 = {
							name: "Muni 6-9 yrs",
							taxable: overview.value.muni69T,
							percentT: overview.value.muni69T / overview.value.totalT,
							nontaxable: overview.value.muni69NT,
							percentNT: overview.value.muni69NT / overview.value.totalNT
						};
						const muni10p = {
							name: "Muni 10+ yrs",
							taxable: overview.value.muni10pT,
							percentT: overview.value.muni10pT / overview.value.totalT,
							nontaxable: overview.value.muni10pNT,
							percentNT: overview.value.muni10pNT / overview.value.totalNT
						};
						const cash = {
							name: "Cash",
							taxable: overview.value.cashT,
							percentT: overview.value.cashT / overview.value.totalT,
							nontaxable: overview.value.cashNT,
							percentNT: overview.value.cashNT / overview.value.totalNT
						};

						tableOverview.value = [
							total,
							sleeve,
							model,
							leagcyRestricted,
							outside,
							treasury01,
							treasury12,
							treasury35,
							treasury69,
							corporate01,
							corporate12,
							corporate35,
							corporate69,
							muni01,
							muni12,
							muni35,
							muni69,
							muni10p,
							cash
						];
						// displayTicker.value = ticker.value;
					} else {
						$error("Could not load Port. Summary");
					}
					loading.value = false;
				} catch (err) {
					console.log("Failed to get Port. Summary");
					$error("400 error: Failed to Load Port. Summary");
					loading.value = false;
				}
			};

			onMounted(() => {
				portSummaryOverview();
			});

			return {
				loading,
				date,
				overview,
				favoriteAssets,
				combinedPortSummary,
				tableOverview,
				optionalFirstColumn,
				optionalSecondColumn,
				trim,
				asDollars,
				formatDate,
				formatOptionalColumn,
				portSummaryOverview
			};
		}
	};
</script>
<style lang="scss">
	.portSummaryTablesClass {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1rem;
		margin: 0 -2rem;
		padding-left: 1.5rem;
		// justify-content: space-around;
	}

	.portSummaryTablesClass {
		width: auto;
		min-width: 0;
	}
	.portSummaryTablesClass tr td,
	th {
		:last-child {
			// padding-right: 1.5rem;
		}
	}
	.portSummaryTablesClass tr td,
	.portSummaryTablesClass tr td {
		&:nth-child(1) {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
			padding-top: 0.1rem;
			padding-bottom: 0.1rem;

			text-align: left !important;
			font-weight: 600;
			background-color: #6ba543;
			// background-color: #9dc482; #e4e4e4  #588e2f; e4e4e4

			color: white;
		}
		&:nth-child(2) {
			padding-left: 2rem;
		}
		// &:nth-child(4) {
		// 	text-align: right !important;
		// 	direction: rtl;
		// }
	}
	.portSummaryTablesClass th {
		&:nth-child(1) {
			background-color: #f9f9f9;
		}
		&:nth-child(2) {
			border-top-left-radius: 0.3rem;
		}
	}
	.portSummaryTablesClass tr {
		&:nth-child(1) {
			& td {
				// font-weight: 600;
				&:nth-child(1) {
					border-top-left-radius: 0.3rem;
				}
			}
		}
	}
	#portfolioOverview {
		tr td,
		th {
			&:nth-child(1) {
				min-width: 12rem;
				// white-space: nowrap;
				overflow: hidden;
			}
			&:nth-child(2) {
				text-align: center;
				width: 11rem;
			}
			&:nth-child(3) {
				width: 1.5rem;
			}
			&:nth-child(4) {
				text-align: center;
				width: 11rem;
			}
			&:nth-child(5) {
				width: 1.5rem;
			}
			&:nth-child(6) {
				width: 1.5rem;
			}
			text-align: left;
		}
		tr {
			&:nth-child(1) {
				font-weight: 600;
				max-width: 12rem;
			}
		}
		tr {
			&:nth-child(odd) td {
				&:nth-child(1) {
					background-color: #7eb15b;
				}
			}
			&:nth-child(even) td {
				&:nth-child(1) {
					background-color: #6ca543;
				}
			}
		}
	}
	#assetOverview {
		// margin: 0 auto;
		tr td,
		th {
			&:nth-child(1) {
				// max-width: 19rem;
				// width: 1px;
				white-space: nowrap;
				overflow: hidden;
				// margin: 0 auto;
			}
			&:nth-child(2) {
				text-align: center;
				width: 11rem;
			}
			&:nth-child(3) {
				width: 1.5rem;
			}
			&:nth-child(4) {
				text-align: center;
				width: 11rem;
			}
			&:nth-child(5) {
				width: 1.5rem;
			}
			&:nth-child(6) {
				width: 1.5rem;
			}
			&:nth-child(7) {
				width: 1.5rem;
			}
			&:nth-child(8) {
				width: 1.5rem;
				padding-right: 1.5rem;
			}
			&:nth-child(9) {
				width: 4.5rem;
			}
			text-align: left;
		}
		tr {
			&:nth-child(odd) td {
				&:nth-child(1) {
					background-color: #7eb15b;
				}
			}
			&:nth-child(even) td {
				&:nth-child(1) {
					background-color: #6ca543;
				}
			}
		}
	}
</style>


