<template>
    <fa-icon icon="spinner" pulse></fa-icon>
</template>
<script>
    import { ref, computed, watch, onMounted } from "vue";
    export default {
        props: {},
        setup() {
            return {}
        }
    }
</script>