<template>
    <div class="is-flex is-justify-content-space-around my-2">
        <div style="min-width: 560px; color: #000">
            <h3 class="is-size-6 has-text-centered">Summary</h3>
            <div class="is-flex is-justify-content-space-between mb-4 mt-2">
                <div><b>{{ lastData?.company_name }} - ${{ lastData?.price_2 }}</b></div>
                <div>{{ lastData?.industry }} ({{ lastIndustry?.count_of_member_companies }})</div>
                <div><b>var. {{ FormatPercent(lastData?.variance, 1) }}</b></div>
            </div>
            <div class="columns has-text-centered">
                <div class="column">
                    <div>Market Cap</div>
                    <div>${{ FormatSignificant(lastData?.cap) }} / <span class="has-text-grey">${{ FormatSignificant(lastIndustry?.cap) }}</span></div>
                </div>
                <div class=" column">
                    <div class="has-text-centered">Cash(Debt) & Debt/Ebitda</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>${{ FormatSignificant(lastData?.cash - lastData?.debt) }}</div>
                        <div>{{ FormatDecimal(lastData?.debt_by_ebitda, 1) }}/<span class="has-text-grey">{{ FormatDecimal(lastIndustry?.debt_by_ebitda, 1) }}</span></div>
                    </div>
                </div>
                <div class="column">
                    <div>Rating & Bonds v AA</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ lastData?.financial_strength }}</div>
                        <div>{{ FormatPercent(lastData?.bond_spread) }}</div>
                    </div>
                </div>
            </div>
            <div class="columns has-text-centered">
                <div class="column">
                    <div>Sales Growth (est)</div>
                    <div>{{ FormatPercent(lastData?.best_sales_lt_org, 1) }} / <span class="has-text-grey">{{ FormatPercent(lastIndustry?.best_sales_lt_org, 1) }}</span></div>
                </div>
                <div class="column">
                    <div>P/Sales & 5 yr Median</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ FormatDecimal(lastData?.price_by_sales) }} / <span class="has-text-grey">{{ FormatDecimal(lastIndustry?.price_by_sales) }}</span></div>
                        <div>{{ FormatDecimal(lastData?.median_of_price_by_sales) }} / <span class="has-text-grey">{{ FormatDecimal(lastIndustry?.median_of_price_by_sales) }}</span></div>
                    </div>
                </div>
                <div class="column">
                    <div>Sigma & 14d RSI</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ FormatPercent(lastData?._3d_post_earnings_price_move_0fq) }} / <span class="has-text-grey">{{ FormatPercent(lastIndustry?._3d_post_earnings_price_move_0fq) }}</span></div>
                        <div>{{ FormatDecimal(lastData?._14d_rsi, 0) }} / <span class="has-text-grey">{{ FormatDecimal(lastIndustry?._14d_rsi, 0) }}</span></div>
                    </div>
                </div>
            </div>
            <div class="columns has-text-centered">
                <div class="column">
                    <div>Gr. & Op. Margin</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ FormatPercent(lastData?.best_gross_margin, 0) }} / <span class="has-text-grey">{{ FormatPercent(lastIndustry?.best_gross_margin, 0) }}</span></div>
                        <div>{{ FormatPercent(lastData?.best_op_margin, 0) }} / <span class="has-text-grey">{{ FormatPercent(lastIndustry?.best_op_margin, 0) }}</span></div>
                    </div>
                </div>
                <div class="column">
                    <div>P/E ttm & 15 yr Median</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ FormatDecimal(lastData?.p_by_e, 1) }} / <span class="has-text-grey">{{ FormatDecimal(lastIndustry?.p_by_e, 1) }}</span></div>
                        <div>{{ FormatDecimal(lastData?.p_by_e_median15, 1) }}</div>
                    </div>
                </div>
                <div class="column">
                    <div>P/E ntm & 5 yr Median</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ FormatDecimal(lastData?.price_by_estimate_best_eps, 1) }}</div>
                        <div>{{ FormatDecimal(lastData?.p_by_estimate_median5, 1) }}</div>
                    </div>
                </div>
            </div>
            <div class="columns has-text-centered">
                <div class="column">
                    <div>Yield</div>
                    <div>{{ FormatPercent(lastData?.yield, 2) }} / <span class="has-text-grey">{{ FormatPercent(lastIndustry?.yield, 2) }}</span></div>
                </div>
                <div class="column">
                    <div>Est/3M ago/6M ago</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>${{ FormatDecimal(lastData?.today_est_revision, 2) }}</div>
                        <div>${{ FormatDecimal(lastData?._3_months_ago, 2) }}</div>
                        <div>${{ FormatDecimal(lastData?._6_months_ago, 2) }}</div>
                    </div>
                </div>
                <div class="column">
                    <div>Rel., Value & Growth</div>
                    <div class="is-flex is-justify-content-space-around">
                        <div>{{ FormatPercent(lastData?.rel_reversion, 0) }}</div>
                        <div>{{ FormatPercent(lastData?.abs_reversion, 0) }}</div>
                        <div>{{ FormatPercent(lastData?.growth_reversion, 0) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h3 class="is-size-6 has-text-centered">Earnings</h3>
            <o-table style="font-size: 14px;" table-class="custom research-growth mb-5" :data="releases">
                <o-table-column label="Date" field="latest_announcement_date" v-slot="{ row }">
                    {{ row.latest_announcement_date }}
                </o-table-column>
                <o-table-column label="EPS" field="EPS" v-slot="{ row }">
                    {{ FormatDecimal(row.q0 * maxSplitFactor / row.dividend, 2) }}
                </o-table-column>
                <o-table-column label="Sales" field="sales" v-slot="{ row }">
                    {{ FormatSignificant(row.sales) }}
                </o-table-column>
                <o-table-column label="KPI" field="KPI" v-slot="{ row }">
                    {{ FormatPercent(row.kpi, 1) }}
                </o-table-column>
                <o-table-column label="Cash" field="Cash" v-slot="{ row }">
                    {{ FormatSignificant(row.cash) }}
                </o-table-column>
                <o-table-column label="Debt" field="Debt" v-slot="{ row }">
                    {{ FormatSignificant(row.debt) }}
                </o-table-column>
                <o-table-column label="Shares" field="Shares" v-slot="{ row }">
                    {{ FormatSignificant(row.is_sh_for_diluted_eps * row.dividend / maxSplitFactor) }}
                </o-table-column>
            </o-table>
        </div>
        <div>
            <h3 class="is-size-6 has-text-centered">Long-Term Growth Rates</h3>
            <o-table style="font-size: 14px;" table-class="custom research-growth mb-5" :data="data">
                <o-table-column label="Years" field="year" v-slot="{ row }">
                    {{ row.year }}
                </o-table-column>
                <o-table-column label="Earnings" field="earnings" v-slot="{ row }">
                    {{ FormatPercent(row.earnings, 1) }}
                </o-table-column>
                <o-table-column label="Dividends" field="dividends" v-slot="{ row }">
                    {{ FormatPercent(row.dividends, 1) }}
                </o-table-column>
                <o-table-column label="Total Returns" field="total_return" v-slot="{ row }">
                    {{ FormatPercent(row.total_return, 1) }}
                </o-table-column>
                <o-table-column label="Group" field="industry" v-slot="{ row }">
                    {{ FormatPercent(row.industry, 1) }}
                </o-table-column>
                <o-table-column label="S&P" field="index" v-slot="{ row }">
                    {{ FormatPercent(row.index, 1) }}
                </o-table-column>
            </o-table>
        </div>
    </div>
</template>
<script>
    import { ref, inject, computed, onMounted } from "vue";
    import functions from "@/components/research/functions.js";
    import { max } from "moment";

    export default {
        props: {
            startDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date(new Date().getFullYear() - 1, 1, 1)
                }
            },
            endDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date()
                }
            },
            rawData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            industryData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            indexData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            rawReleases: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        setup: (props, context) => {
            const moment = inject("$moment");

            function GetGroupsByYear(yearArr) {
                if (props.rawData.length == 0) return [];

                const arr = [];
                for (const i in yearArr) {
                    const yearsAgo = yearArr[i];

                    const reversed = props.rawData.slice(0).reverse();
                    const reversedIndustry = props.industryData.slice(0).reverse();

                    const reversedIndex = props.indexData.slice(0).reverse();
                    const lastIndex = yearsAgo * 12 - 1;
                    const obj = {
                        year: yearsAgo,
                        earnings: Math.pow((functions.trailing_earnings(reversed[0]) / functions.trailing_earnings(reversed[lastIndex])), 1 / yearsAgo) - 1,
                        dividends: Math.pow((functions.dividend(reversed[0]) / functions.dividend(reversed[lastIndex])), 1 / yearsAgo) - 1,
                        total_return: Math.pow(reversed.slice(0, lastIndex + 1).map(d => functions.total_return(d)).reduce((acc, n) => acc * n, 1), 1 / yearsAgo) - 1,
                        industry: Math.pow(reversedIndustry.slice(0, lastIndex + 1).map(d => functions.total_return(d)).reduce((acc, n) => acc * n, 1), 1 / yearsAgo) - 1,
                        index: Math.pow(reversedIndex.slice(0, lastIndex + 1).map(d => functions.total_return(d)).reduce((acc, n) => acc * n, 1), 1 / yearsAgo) - 1
                    }

                    arr.push(obj);
                }
                return arr;
            }

            const maxSplitFactor = computed(() => {
                return releases.value[0]?.dividend ?? 1;
            });

            const lastPeriod = computed(() => {
                var index = props.rawData.findIndex(x => x.related_date == props.endDate.format("YYYY-MM-DD"));
                var rtn = props.rawData[index]?.period || 0;
                return rtn;
            });

            const lastData = computed(() => {
                return props.rawData.find(x => x.period == lastPeriod.value);
            });

            const lastIndustry = computed(() => {
                var rtn = props.industryData.find(x => x.period == lastPeriod.value);
                return rtn;
            });

            const data = computed(() => {
                const yearData = GetGroupsByYear([1, 3, 5, 10, 20]);
                return yearData;
            });

            const releases = computed(() => {
                var currentReleases = props.rawReleases.filter(x => x.latest_announcement_date <= props.endDate.format("YYYY-MM-DD"));
                return currentReleases.length >= 6 ? currentReleases.slice(0, 6) : currentReleases;
            });

            function FormatPercent(n, digits = 2) {
                if (!n) return '--';

                const formatter = new Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits: digits, maximumFractionDigits: digits });
                const result = formatter.format(n);
                return result;
            }

            function FormatDecimal(n, digits = 2) {
                if (!n) return '--';

                const formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: digits, maximumFractionDigits: digits });
                const result = formatter.format(n);
                return result;
            }

            function FormatSignificant(n, significant = 4) {
                if (!n || n == 0) return '--';


                const sign = Math.sign(n);
                const abs = Math.abs(n);
                const log = Math.floor(Math.log10(abs));
                const scale = Math.pow(10, log - significant + 1);
                let result = Math.round(abs / scale) * scale;

                var factor = 0;
                while (result > 1000) {
                    result /= 1000;
                    factor++;
                }

                //find number of digits before decimal 
                var digits = Math.floor(Math.log10(result)) + 1;

                return FormatDecimal(result * sign, 4 - digits) + ' ' + GetFactorSuffix(factor);
            }

            function GetFactorSuffix(n) {
                if (n == 0)
                    return 'M';
                if (n == 1)
                    return 'B';
                if (n == 2)
                    return 'T';
                return '';
            }

            return {
                data,
                FormatPercent,
                FormatDecimal,
                FormatSignificant,
                lastData,
                lastIndustry,
                lastPeriod,
                maxSplitFactor,
                releases
            }
        }
    }
</script>
<style>
    .columns {
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;
        border-collapse: collapse;
    }

    .column {
        padding: 0.3rem !important;
        border-right: solid 1px #ccc;
        border-left: solid 1px #ccc;
    }

    table.research-growth {
        width: 100%;
    }

    table.research-growth td {
        text-align: initial;
        max-width: auto;
        padding-right: initial;
    }
</style>
