import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
import Oruga from '@oruga-ui/oruga-next';
import '@oruga-ui/oruga-next/dist/oruga-full.css';
import '@/assets/css/global.scss';
//import { bulmaConfig } from '@oruga-ui/theme-bulma'; //TODO: Implement this properly
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/js/all.js';
import momentjs from 'moment';
//import braintree from 'braintree-web';
import Components from "@/components/index";
import Services from "@/services/index";
import "@/services/raygun";


Axios.defaults.baseURL = process.env.VUE_APP_API;
Axios.defaults.withCredentials = true;
//Axios.interceptors.request.use(config => { config.headers["Authorization"] = "Bearer " + localStorage.getItem("AUTH_TOKEN"); return config; });

const app = createApp(App);
app
    .component("loading", Components.loading)
    .component("fa-icon", FontAwesomeIcon);
app
    .provide("$http", Axios)
    .provide("$moment", momentjs)
app
    .use(Oruga, {
        iconPack: 'fas',
        iconComponent: 'vue-fontawesome',
        button: {
            rootClass: 'button',
            outlinedClass: () => {
                return 'is-outlined'
            },
            disabledClass: 'btn-disabled',
            variantClass: 'is-',
            sizeClass: 'is-'
        },
        field: {
            groupedClass: 'field--groupd',
            rootClass: 'field',
            labelClass: 'label',
            messageClas: 'form-text',
            variantClass: 'field-',
            labelSizeClass: 'is-',
        },
        input: {
            inputClass: 'input',
            variantClass: 'is-',
            iconRightClass: 'input-icon-right'
        },
        icon: {
            variantClass: 'input-icon-'
        },
        select: {
            sizeClass: 'is-'
        },
        dropdown: {
            rootClass: 'dropdown is-active',
            itemClass: 'dropdown-item',
            itemActiveClass: 'is-div-active'
        },
        steps: {
            itemHeaderActiveClass: 'steps-nav-item-active',
            itemHeaderPreviousClass: 'steps-nav-item-previous',
            stepMarkerClass: 'step-marker',
            stepDividerClass: 'step-divider'
        },
        checkbox: {
            override: true,
            rootClass: 'checkbox',
            labelClass: 'checkbox-label'
        },
        modal: {
            closeClass: "modal-close",
            contentClass: "modal-content",
            //fullScreenClass: "is-fullscreen",
            //mobileClass: "modal-content",
            overlayClass: "modal-background",
            rootClass: "modal is-active"
        },
        //...bulmaConfig //TODO: Implement this properly
    })
    .use(Services.notification.Success)
    .use(Services.notification.Error)
    .use(Services.notification.Warn)
    .use(store)
    .use(router);
app.provide("$oruga", app.config.globalProperties.$oruga)
app.mount('#app');


//How to customize Oruga: https://github.com/oruga-ui/demo-multiframework/blob/master/src/views/Bulma.vue
