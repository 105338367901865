import shared from "@/components/research/shared.js";
import functions from "@/components/research/functions.js";
import { toFont } from "chart.js/helpers";

const externalTooltip = function (context) {
    const allCharts = shared.GetCharts();

    let tooltipEl = document.getElementById('chartjs-tooltip');
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table class="tooltip" style="z-index: 1000;"></table>';
        document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }


    if (tooltipModel.body) {
        const titleLines = tooltipModel.title || [];

        let innerHtml = '<thead>';

        titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const currencyFormatterNoCents = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });

        const numberFormatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        });

        const percentFormatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        });

        const dataIndex = context.tooltip.dataPoints[0].dataIndex;
        //context.chart.data.datasets.forEach(function (row) {
        //    const styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        //    const style = styles.join(";");
        //    const val = row.data[dataIndex]
        //    const line = `<span style="${style}"></span> ${row.label}: ` + formatter.format(val);
        //    innerHtml += `<tr><td>${line}</td></tr>`
        //})

        var row = allCharts[0].data.datasets.find(x => x.label == "Price");

        var styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        var style = styles.join(";");
        var value = row.data[dataIndex];
        const price = value;

        var line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(value) || !isFinite(value) ? "-" : currencyFormatter.format(value));
        innerHtml += `<tr><td>${line}</td></tr>`;

        row = allCharts[0].data.datasets.find(x => x.label == "Avg Price");
        styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        style = styles.join(";");
        value = row.data[dataIndex];

        line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(value) || !isFinite(value) ? "-" : currencyFormatter.format(value));
        innerHtml += `<tr><td>${line}</td></tr>`;

        row = allCharts[0].data.datasets.find(x => x.label == "EPS");
        styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        style = styles.join(";");
        value = row.data[dataIndex];

        line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(value) || !isFinite(value) ? "-" : currencyFormatter.format(value)) + ' (' + numberFormatter.format(price / value) + 'x)';
        innerHtml += `<tr><td>${line}</td></tr>`;

        row = allCharts[0].data.datasets.find(x => x.label == "EPS Estimate");
        styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        style = styles.join(";");
        value = row.data[dataIndex];

        line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(value) || !isFinite(value) ? "-" : currencyFormatter.format(value)) + ' (' + numberFormatter.format(price / value) + 'x)';
        innerHtml += `<tr><td>${line}</td></tr>`;

        row = allCharts[0].data.datasets.find(x => x.label == "EPS 2 yr. Estimate");
        styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        style = styles.join(";");
        value = row.data[dataIndex];

        line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(value) || !isFinite(value) ? "-" : currencyFormatter.format(value)) + ' (' + numberFormatter.format(price / value) + 'x)';
        innerHtml += `<tr><td>${line}</td></tr>`;

        row = allCharts[0].data.datasets.find(x => x.label == "Dividend");
        styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
        style = styles.join(";");
        value = row.data[dataIndex];

        line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(value) || !isFinite(value) ? "-" : currencyFormatter.format(value)) + ' (' + percentFormatter.format(value / price) + ')';
        innerHtml += `<tr><td>${line}</td></tr>`;


        allCharts.forEach(function (chart) {
            //console.log(chart.config.options.z_index);
            if (chart.config.options.z_index == 0) return;

            var formatter = currencyFormatterNoCents;


            const chart_title = chart.config.options?.name || chart.canvas?.id?.replace("_", " ") || chart.id;
            //innerHtml += `<tr><td><span style="font-size: 12px">${chart_title}</span></td></tr>`;
            chart.data.datasets.forEach(function (row) {
                //console.log(row);
                const styles = [`border: solid 2px ${functions.getColor(row.borderColor, 1)}`, `background-color:${functions.getColor(row.borderColor, 0.7)}`, 'padding: 4px', 'display: inline-block', 'width: 15px', 'height: 15px', 'vertical-align: bottom'];
                const style = styles.join(";");
                var val = row.data[dataIndex];

                if (chart.config.options.z_index == 2) {
                    formatter = percentFormatter;
                }


                const line = `<span style="${style}"></span> ${row.label}: ` + (isNaN(val) || !isFinite(val) ? "-" : formatter.format(val));
                innerHtml += `<tr><td>${line}</td></tr>`;
            });
        });


        innerHtml += '</tbody>';

        let tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    const bodyFont = toFont(tooltipModel.options.bodyFont);

    let yOffset = 0;
    let xOffset = 40; //110;

    if (context.chart.legend.position == "top") {
        yOffset += context.chart.legend.height;
    }

    {
        xOffset += context.chart.scales.x.left;
        xOffset += context.chart.chartArea.left;
    }

    //tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    //tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.left = position.left + window.pageXOffset + xOffset + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + yOffset + 'px';
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.display = context.chart.config.options.plugins?.tooltip?.hideTooltip ? "none" : "block";
    //console.log(context.chart.config.options.name, context.chart);
}

export default externalTooltip;
