<template>
    <div>
        <!-- {{loading}} -->

        <!-- <div class="topOfAsset"> -->
        <div style="width: 100%; display: flex">
            <h1>Assets Summary</h1>
            <o-button @click="update52WeekHighs" style="margin-left: auto"><fa-icon icon="sync" style="margin-right: 8px" :spin="loading"></fa-icon>Update Asset Metrics</o-button>
        </div>
        <div>
            <o-field>
                <o-switch v-model="showSchwabEmployeeAssets">
                    Schwab Employee assets
                </o-switch>
            </o-field>
        </div>
        <div>
            <ag-grid-vue class="ag-theme-alpine" :rowData="assetListFiltered" domLayout="500" style="height: 55rem" :defaultColDef="defaultColDef" :columnDefs="columnDefs.value" @grid-ready="onGridReady"></ag-grid-vue>
        </div>
    </div>
</template>
<script lang="ts">
import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";

export default {
    props: {},
    emits: [],
    components: {
        AgGridVue
    },
    setup() {
        const columns = ref({
            metric1: "Price",
            metric2: "ChangePCT",
            metric3: "High52",
            metric4: "Low52",
            metric5: "Metric5",
            metric6: "Metric6"
        });

        document.title = "Asset Summary";
        const gridApi = ref(null); // Optional - for accessing Grid's API

        // Obtain API from grid's onGridReady event
        const onGridReady = (params) => {
            gridApi.value = params.api;

            params.columnApi.autoSizeAllColumns();
        };

        const defaultColDef = {
            resizible: true,
            sortable: true,
            width: 90,
            filter: "agTextColumnFilter",
            floatingFilter: true
            // headerComponentParams: {
            // 	template:
            // 		'<div class="ag-cell-label-container" role="presentation">' +
            // 		'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            // 		'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            // 		'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            // 		'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            // 		'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            // 		'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            // 		'    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            // 		'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            // 		"  </div>" +
            // 		"</div>"
            // }
        };
        const formatDate = (dateTime) => {
            const date = new Date(dateTime);
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };
        const dateFormat = function (params) {
            if (params.value == null) return "";
            const date = new Date(params.value);
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };

        const currencyFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 0);
        };

        const currencyCentsFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 2);
        };

        const integerFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 0);
        };

        const singleDecimalFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 1);
        };

        const numberFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 2);
        };
        const percentFormatterRaw = function (params) {
            if (params.value == null) return "";
            //params.value *= 100;
            return formatNumber(params, 2) + "%";
        };
        const percentFormatter = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 0) + "%";
        };
        const percentFormatterWithoutPercent = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 1);
        };

        const formatNumber = function (params, precision = 0) {
            if (params.value == null) return "";
            return parseFloat(params.value)
                .toFixed(precision)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        };

        const rowData = reactive({});
        const columnDefs = reactive({
            value: [
                {
                    headerName: "General",
                    children: [
                        // { field: "ticker", width: 120, cellRenderer: (params) => {return '<a href = "/asset/'+ params.value + '"> '+' '} },
                        {
                            field: "ticker",
                            filter: "agTextColumnFilter",
                            width: 120,
                            cellRenderer: (params) => {
                                return (
                                    '<a href = "/asset/' +
                                    params.value +
                                    '"  target="_blank"> ' +
                                    params.value +
                                    " </a>"
                                );
                            },
                            resizable: true
                        },

                        { field: "name", columnGroupShow: "open", minWidth: 200, resizable: true },
                        { field: "sector", columnGroupShow: "open", minWidth: 200, resizable: true }
                    ]
                },
                {
                    headerName: "PS Position",
                    children: [
                        {
                            field: "value",
                            minWidth: 120,
                            columnGroupShow: "always",
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "accounts",
                            minWidth: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            headerName: "Accounts",
                            valueFormatter: integerFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "cost",
                            minWidth: 120,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "count",
                            minWidth: 110,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            headerName: "Shares",
                            valueFormatter: integerFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "outside",
                            minWidth: 120,
                            columnGroupShow: "open",
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        }
                    ]
                },
                {
                    headerName: "Hi/Lo",
                    openByDefault: false,
                    children: [
                        {
                            field: "Metric1",
                            headerName: columns.value.metric1,
                            headerValueGetter: () => columns.value.metric1,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyCentsFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "Metric2",
                            columnGroupShow: "open",
                            headerName: columns.value.metric2,
                            headerValueGetter: () => columns.value.metric2,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatterRaw,
                            type: "rightAligned",
                            resizable: true,
                            width: 105
                        },
                        {
                            field: "Metric3",
                            columnGroupShow: "open",
                            headerName: columns.value.metric3,
                            headerValueGetter: () => columns.value.metric3,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 105
                        },
                        {
                            field: "Metric4",
                            columnGroupShow: "open",
                            headerName: columns.value.metric4,
                            headerValueGetter: () => columns.value.metric4,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 105
                        },
                        {
                            field: "Metric5",
                            columnGroupShow: "open",
                            headerName: columns.value.metric5,
                            headerValueGetter: () => columns.value.metric5,
                            filter: "agNumberColumnFilter",
                            valueFormatter: numberFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 105
                        },
                        {
                            field: "Metric6",
                            columnGroupShow: "open",
                            headerName: columns.value.metric6,
                            headerValueGetter: () => columns.value.metric6,
                            filter: "agNumberColumnFilter",
                            valueFormatter: numberFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 105
                        }
                    ]
                },
                {
                    headerName: "Scoring System",
                    // openByDefault: false,
                    children: [
                        {
                            field: "pe",
                            headerName: "PE",
                            filter: "agNumberColumnFilter",
                            valueFormatter: singleDecimalFormatter,
                            // columnGroupShow: "closed",
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "pe_ntm",
                            headerName: "PE(NTM)",
                            filter: "agNumberColumnFilter",
                            valueFormatter: singleDecimalFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 100
                        },
                        {
                            field: "quality",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            // columnGroupShow: "closed",
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                            // columnGroupShow: "open"
                        },
                        {
                            field: "score",
                            filter: "agNumberColumnFilter",
                            valueFormatter: singleDecimalFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                        },
                        {
                            field: "orgGrowth",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                        },
                        /*
                        {
                            field: "range",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                        },
                        */
                        {
                            field: "returnYTD",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125
                        },
                        {
                            field: "return1y",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125
                        },
                        {
                            field: "return3y",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125
                        },
                        {
                            field: "return5y",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125
                        },

                        {
                            field: "releaseDate",
                            // filter: "Release Date",
                            valueFormatter: dateFormat,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125,
                            filter: "agDateColumnFilter"
                        },
                        {
                            field: "kpi",
                            headerName: "KPI",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                            //make 1dec
                        },
                        /*
                        {
                            field: "safety",
                            headerName: "Safety",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                        },
                        */
                        {
                            field: "finStrength",
                            headerName: "Fin. Strength",
                            // valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125
                        },
                        {
                            field: "momentum",
                            headerName: "Momentum",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true,
                            minWidth: 125
                        },
                        {
                            field: "sigma",
                            headerName: "Sigma",
                            valueFormatter: percentFormatterWithoutPercent,
                            type: "rightAligned",
                            columnGroupShow: "open",
                            resizable: true
                            //percent without %
                        }
                        // ,
                        // {
                        // 	field: "customBondFundMaturity",
                        // 	headerName: "Custom Bond Fund Maturity",
                        // 	valueFormatter: singleDecimalFormatter,
                        // 	type: "rightAligned",
                        // 	columnGroupShow: "open",
                        // 	resizable: true
                        // 	//percent without %
                        // },
                        // {
                        // 	field: "yearsUntilBondMatured",
                        // 	headerName: "Years Until Matured",
                        // 	valueFormatter: singleDecimalFormatter,
                        // 	type: "rightAligned",
                        // 	columnGroupShow: "open",
                        // 	resizable: true
                        // 	//percent without %
                        // }
                    ]
                }
            ]
        });

        // const global = getCurrentInstance()?.appContext.config.globalProperties; //probably avoiding to use global and will inject instead
        const $http = inject("$http") as any; //for posts and such
        const $moment = inject("$moment") as any; //date math
        const $success = inject("$success") as any; // $success("It worked!")
        const $error = inject("$error") as any;


        const loading = ref(false);
        const profile = ref("");
        const date = ref($moment.now());
        const assetList = ref();
        const assetSummary = ref();
        const optionalFirstColumn = ref("pe");
        const optionalSecondColumn = ref("quality");

        profile.value = "hi";

        const trim = (val, num = 0) => {
            if (num === 0) return Math.floor(val);

            const d = Math.pow(10, num);
            const e = Math.floor(val * d) / d;
            return e.toFixed(num);
        };

        const asDollars = (val, num = 0) => {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: num,
                maximumFractionDigits: num
            }).format(val);
        };

        const formatOptionalColumn = (header, value) => {
            if (value == null) {
                return "-";
            } else if (header == "pe_ntm") {
                return trim(value, 1);
            } else if (header == "pe") {
                return trim(value, 1);
            } else if (header == "range") {
                return trim(value * 100) + "%";
            } else if (header == "return5y") {
                return trim(value * 100) + "%";
            } else if (header == "return3y") {
                return trim(value * 100) + "%";
            } else if (header == "return1y") {
                return trim(value * 100) + "%";
            } else if (header == "returnYTD") {
                return trim(value * 100) + "%";
            } else if (header == "quality") {
                return trim(value * 100) + "%";
            } else if (header == "releaseDate") {
                return formatDate(value);
            } else if (header == "kpi") {
                return trim(value, 2) + "%";
            } else if (header == "momentum") {
                return trim(value * 100) + "%";
            } else if (header == "sigma") {
                return trim(value * 100, 1);
            } else return value;
        };

        const reportAsset = async () => {
            loading.value = true;
            try {
                var columnResponse = await $http.get("/api/asset/metrics/names");
                if (columnResponse.status == 200) {
                    columns.value = columnResponse.data;
                }

                var response = await $http.get("/api/asset/summary");
                if ((response.status == 200 && response.data.length != 0)) {
                    $success("Assets Summary");

                    assetList.value = response.data;
                } else {
                    $error("Could Not Load Assets Summary");
                }
                loading.value = false;
            } catch (err) {
                $error("400 error: Failed to Load Assets Summary");
                loading.value = false;
            }
        };

        const showSchwabEmployeeAssets = ref(false);

        const assetListFiltered = computed(() => {
            if (showSchwabEmployeeAssets.value) {
                return assetList.value;
            } else {
                return assetList.value?.filter(x => x.isSchwabEmployee === false);
            }
        });

        // const filteredReportAsset = computed(() => {});
        const showHH = async () => {
            //take to new page
        };
        const update52WeekHighs = async () => {
            loading.value = true;
            var response = await $http.get("/api/asset/update52WeekHighs");
            loading.value = false;
            window.location.reload();
        };

        onMounted(() => {
            reportAsset();
        });
        return {
            loading,
            profile,
            date,
            assetList,
            optionalFirstColumn,
            optionalSecondColumn,
            trim,
            asDollars,
            formatDate,
            dateFormat,
            formatOptionalColumn,
            reportAsset,
            showHH,
            update52WeekHighs,
            columnDefs,
            defaultColDef,
            onGridReady,
            showSchwabEmployeeAssets,
            assetListFiltered
        };
    }
};
</script>
<style lang="scss">
.assetsSummary table {
    // width: 100%;
}

.assetsSummary tr td,
.assetsSummary th {
    &:nth-child(1) {
        width: 6.65rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(2) {
        width: 22rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(3) {
        width: 20rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(4) {
        width: 9rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(5) {
        width: 6.65rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(6) {
        width: 6.65rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(7) {
        width: 6.65rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(8) {
        width: 6.65rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:nth-child(9) {
        width: 6.8rem;
        padding-left: 1.2rem;
        // padding-right: 1.2rem;
    }

    &:nth-child(10) {
        width: 6.65rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &:nth-child(11) {
        width: 6.65rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    // &:nth-child(2) {
    // 	padding-left: 1.2rem;
    // 	// padding-right: 1.5rem;
    // }
    // &:nth-child(3) {
    // 	width: 1.2rem;
    // 	padding: 0;
    // }
    // &:nth-child(4) {
    // 	// min-width: 7.2rem !important;
    // 	padding-left: 1.2rem;
    // 	// padding-right: 0rem;
    // }
    // &:nth-child(5) {
    // 	// padding-left: 1.5rem;
    // 	max-width: 5rem;
    // }
    // &:nth-child(6) {
    // 	padding-left: 1.2rem;
    // }
    // &:nth-child(7) {
    // 	max-width: 5rem;
    // 	// padding-left: 1.2rem;
    // }
}

.assetsSummary tr td {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        text-align: left !important;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
        text-align: right !important;
    }
}
</style>
