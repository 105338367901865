<template>
    <div>
        <!-- {{loading}} -->

        <div class="topOfAsset">
            <div>
                <h1>Asset Summary: {{ displayTicker }}</h1>
                <!-- <br /> -->
                <o-field grouped>
                    <!-- <o-input placeholder="Ticker" maxlength="15" v-model="ticker"></o-input>
					<o-button @click="reportAsset">Show Summary</o-button> -->
                    <h1 v-if="loading">Loading...</h1>
                </o-field>
            </div>
            <div v-if="assetSummary" class="topOfAssetLists">
                <!-- <ul>
					<li>
						Assets: {{ assetSummary != null ? asDollars(assetSummary?.assetsSummation) : "" }}
					</li>
					<li>Value: {{ assetSummary != null ? asDollars(assetSummary?.valueSummation) : "" }}</li>
					<li>Basis: {{ assetSummary != null ? asDollars(assetSummary?.basisSummation) : "" }}</li>
					<li>ID: {{ assetSummary != null ? assetSummary?.idMode : "" }}</li>
					<li>Date: {{ assetSummary != null ? formatDate(assetSummary?.dateMode) : "" }}</li>
					<li>units: {{ assetSummary != null ? assetSummary?.unitsSummation : "" }}</li>
					<li>#Lots: {{ assetSummary != null ? assetSummary?.numLotsSummation : "" }}</li>
				</ul>
				<ul>
					<li>
						Sleeve: {{ assetSummary != null ? trim(assetSummary.sleeveMedian * 100, 1) + "%" : "" }}
					</li>
					<li>
						Total: {{ assetSummary != null ? trim(assetSummary.totalMedian * 100, 1) + "%" : "" }}
					</li>
					<li>
						Model: {{ assetSummary != null ? trim(assetSummary.modelMedian * 100, 1) + "%" : "" }}
					</li>
					<li>
						Percent:
						{{ assetSummary != null ? trim(assetSummary.percentMedian * 100, 1) + "%" : "" }}
					</li>
					<li>
						Gain: {{ assetSummary != null ? trim(assetSummary.gainMedian * 100, 1) + "%" : "" }}
					</li>
					<li>
						% (Adj.):
						{{
							assetSummary != null ? trim(assetSummary.percentOverSleeveMedian * 100, 1) + "%" : ""
						}}
					</li>
				</ul> -->
            </div>
        </div>
        <div>
            <ag-grid-vue class="ag-theme-alpine" :rowData="assetList" domLayout="500" style="height: 55rem" :defaultColDef="defaultColDef" :columnDefs="columnsDef.value" @grid-ready="onGridReady" :groupIncludeFooter="true" :groupIncludeTotalFooter="true"></ag-grid-vue>
        </div>
        <div v-if="false" class="assetReport">
            <o-table :data="assetList" height="50rem" :debounce-search="1000">
                <!-- <o-table-column label="AID" field="accountID" sortable v-slot="props">
                    {{ props.row.accountID }}
                </o-table-column>
                <o-table-column label="HHID" field="householdID" sortable v-slot="props">
                    {{ props.row.householdID }}
                </o-table-column> -->
                <o-table-column :label="'Last Name'" field="lastName" sortable searchable v-slot="props">
                    <router-link :to="{ name: 'singleHouseHold', params: { hhID: props.row.householdID } }" target="_blank">
                        {{ props.row.lastName }}
                    </router-link>
                </o-table-column>
                <o-table-column label="Account" field="accountName" sortable searchable v-slot="props" class="assetReportColumn">
                    {{ props.row.accountName }}
                </o-table-column>
                <o-table-column aria-label=" " field="accountName">{{}}</o-table-column>
                <o-table-column :label="'Assets' + (assetSummary != null ? '\n' + asDollars(assetSummary?.assetsSummation) : '')
                " field="assets" sortable v-slot="props">
                    {{ asDollars(props.row.assets) }}
                </o-table-column>
                <o-table-column label="Type" field="type" sortable searchable v-slot="props">
                    {{ props.row.type }}
                </o-table-column>
                <o-table-column label="Broker" field="broker" sortable searchable v-slot="props" width="2px">
                    <!-- <span> -->
                    {{ replaceWithCodes(props.row.broker) }}
                    <!-- </span> -->
                </o-table-column>
                <o-table-column :label="'Sleeve ' +
                    (assetSummary != null ? '\n' + trim(assetSummary.sleeveMedian * 100, 1) + '%' : '')
                " field="sleeve" sortable v-slot="props">
                    {{ trim(props.row.sleeve * 100) }}%
                </o-table-column>
                <o-table-column :label="'Total ' +
                    (assetSummary != null ? '\n' + trim(assetSummary.totalMedian * 100, 0) + '%' : '')
                " field="total" sortable v-slot="props">
                    {{ trim(props.row.total * 100) }}%
                </o-table-column>
                <o-table-column :label="'Model ' +
                    (assetSummary != null ? '\n' + trim(assetSummary.modelMedian * 100, 0) + '%' : '')
                " field="model" sortable v-slot="props">
                    {{ trim(props.row.model * 100) }}%
                </o-table-column>
                <o-table-column :label="'Shares ' + '\n' + (assetSummary != null ? assetSummary?.unitsSummation : '')" field="units" sortable v-slot="props">
                    {{ props.row.units }}
                </o-table-column>
                <o-table-column :label="'#Lots ' + '\n' + (assetSummary != null ? assetSummary?.numLotsSummation : '')" field="numLots" sortable v-slot="props">
                    {{ props.row.numLots }}
                </o-table-column>
                <o-table-column :label="'% ' +
                    (assetSummary != null ? '\n' + trim(assetSummary.percentMedian * 100, 1) + '%' : '')
                " field="percent" sortable v-slot="props">
                    {{ trim(props.row.percent * 100, 1) }}%
                </o-table-column>
                <o-table-column :label="'Gain ' +
                    '\n' +
                    (assetSummary != null ? trim(assetSummary.gainMedian * 100, 0) + '%' : '')
                " field="gain" sortable v-slot="props">
                    {{ trim(props.row.gain * 100) }}%
                </o-table-column>
                <o-table-column :label="'% (Adj.) ' +
                    '\n' +
                    (assetSummary != null ? trim(assetSummary.percentOverSleeveMedian * 100, 1) + '%' : '')
                " field="percentOverSleeve" sortable v-slot="props">
                    {{ trim(props.row.percentOverSleeve * 100, 1) }}%
                </o-table-column>
                <o-table-column :label="'ID' + '\n' + (assetSummary != null ? assetSummary?.idMode : '')" field="id" sortable v-slot="props">
                    {{ props.row.id }}
                </o-table-column>
                <o-table-column :label="'Value ' + '\n' + (assetSummary != null ? asDollars(assetSummary?.valueSummation) : '')
                " field="value" sortable v-slot="props">
                    {{ asDollars(props.row.value) }}
                </o-table-column>
                <o-table-column :label="'Basis ' + '\n' + (assetSummary != null ? asDollars(assetSummary?.basisSummation) : '')
                " field="basis" sortable v-slot="props">
                    {{ asDollars(props.row.basis) }}
                </o-table-column>
                <o-table-column :label="'Date ' + '\n' + (assetSummary != null ? formatDate(assetSummary?.dateMode) : '')" field="tradeDate" sortable v-slot="props">
                    {{ formatDate(props.row.tradeDate) }}
                </o-table-column>
            </o-table>
        </div>
    </div>
</template>
<script lang="ts">
import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { useRoute } from "vue-router";
import { toTypeString } from "@vue/shared";
export default {
    props: {},
    emits: [],
    components: {
        AgGridVue
    },
    setup(props) {
        const gridApi = ref(); // Optional - for accessing Grid's API

        // Obtain API from grid's onGridReady event
        const onGridReady = (params) => {
            gridApi.value = params.api;
            console.log(gridApi.value);
            params.columnApi.autoSizeAllColumns();
        };

        const defaultColDef = {
            resizible: true,
            sortable: true,
            width: 200,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div> "
            }
        };
        // const customFunc = function (params) {
        // 	var sum = 0;
        // 	params.forEach(function (value){
        // 		sum += Number(value);
        // 	});
        // 	return sum;
        // }
        const assetList = ref();
        const assetSummary = ref();
        const ticker = ref();
        const displayTicker = ref("");
        const asDollars = (val) => {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(val);
        };
        const currencyFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 0);
        };

        const currencyCentsFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 2);
        };

        const integerFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 0);
        };

        const singleDecimalFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 1);
        };

        const numberFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 2);
        };

        const percentFormatter = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 0) + "%";
        };
        const percentFormatter1dec = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 1) + "%";
        };

        const formatNumber = function (params, precision = 0) {
            if (params.value == null) return "";
            return parseFloat(params.value)
                .toFixed(precision)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        };
        const formatDate = function (params) {
            if (params.value == null) return "";
            const date = new Date(params.value);
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };

        const $http = inject("$http") as any; //for posts and such
        const $moment = inject("$moment") as any; //date math
        const $success = inject("$success") as any; // $success("It worked!")
        const $error = inject("$error") as any;
        const $route = useRoute();

        const loading = ref(false);
        const profile = ref("");
        const date = ref($moment.now());
        const hhData = ref();
        const singleAccountsData = ref();

        const rowData = reactive({});
        const columnsDef = reactive({
            value: [
                {
                    headerName: "Account",
                    children: [
                        {
                            field: "lastName",
                            headerName: "Last Name\n" + " ",
                            // valueFormatter: singleDecimalFormatter,
                            filter: "agTextColumnFilter",
                            type: "leftAligned",
                            testing: "testing",
                            width: 150,
                            resizable: true,
                            cellRenderer: (params) => {
                                return (
                                    '<a href = "/singleHouseHold/' +
                                    params.data.householdID +
                                    '"  target="_blank"> ' +
                                    params.value +
                                    " </a>"
                                );
                            }
                        },
                        {
                            field: "accountName",
                            headerName: "Account",
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Account\n" + params.api.assetSummary.accounts;
                                else return "Account\n" + " ";
                            },
                            // valueFormatter: singleDecimalFormatter,
                            filter: "agTextColumnFilter",
                            type: "leftAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "assets",
                            headerName: "Assets",
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Assets\n" + asDollars(params.api.assetSummary.assetsSummation);
                                else return "Assets\n" + " ";
                            },
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            minWidth: 125,
                            resizable: true
                        },
                        {
                            field: "type",
                            headerName: "Type\n" + " ",
                            // valueFormatter: singleDecimalFormatter,
                            filter: "agTextColumnFilter",
                            type: "leftAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "broker",
                            headerName: "Broker\n" + " ",
                            // valueFormatter: singleDecimalFormatter,
                            filter: "agTextColumnFilter",
                            type: "leftAligned",
                            width: 150,
                            resizable: true
                        },
                        // {
                        // 	field: "weighting",
                        // 	headerName: "Weighting\n" + " ",
                        // 	headerValueGetter: (params) => {
                        // 		if (params.api.assetSummary)
                        // 			return (
                        // 				"Weighting\n" + trim(params.api.assetSummary.weightingMedian * 100, 0) + "%"
                        // 			);
                        // 		else return "Weighting\n" + " ";
                        // 	},
                        // 	valueFormatter: percentFormatter,
                        // 	type: "rightAligned",
                        // 	width: 150,
                        // 	resizable: true
                        // },
                        {
                            field: "sleeve",
                            headerName: "Sleeve\n",
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Sleeve\n" + trim(params.api.assetSummary.sleeveMedian * 100, 1) + "%";
                                else return "Sleeve\n" + " ";
                            },
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "total",
                            headerName: "Total\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Total\n" + trim(params.api.assetSummary.totalMedian * 100, 0) + "%";
                                else return "Total\n" + " ";
                            },
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "model",
                            headerName: "Model\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Model\n" + trim(params.api.assetSummary.modelMedian * 100, 0) + "%";
                                else return "Model\n" + " ";
                            },
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "units",
                            headerName: "Shares\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Shares\n" + params.api.assetSummary.unitsSummation;
                                else return "Shares\n" + " ";
                            },
                            // valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "numLots",
                            headerName: "#Lots\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "#Lots\n" + params.api.assetSummary.numLotsSummation;
                                else return "#Lots\n" + " ";
                            },
                            // valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "percent",
                            headerName: "%\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "%\n" + trim(params.api.assetSummary.percentMedian * 100, 1) + "%";
                                else return "%\n" + " ";
                            },
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "gain",
                            headerName: "Gain\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Gain\n" + trim(params.api.assetSummary.gainMedian * 100, 0) + "%";
                                else return "Gain\n" + " ";
                            },
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "percentOverSleeve",
                            headerName: "%(Adj.)\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return (
                                        "%(Adj.)\n" +
                                        trim(params.api.assetSummary.percentOverSleeveMedian * 100, 1) +
                                        "%"
                                    );
                                else return "%(Adj.)\n" + " ";
                            },
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "id",
                            headerName: "ID\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary) return "ID\n" + params.api.assetSummary.idMode;
                                else return "ID\n" + " ";
                            },
                            // valueFormatter: percentFormatter,
                            type: "rightAligned",
                            width: 150,
                            resizable: true
                        },
                        {
                            field: "value",
                            headerName: "Value\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Value\n" + asDollars(params.api.assetSummary.valueSummation);
                                else return "Value\n" + " ";
                            },
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            minWidth: 125,
                            resizable: true
                        },
                        {
                            field: "basis",
                            headerName: "Basis\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Basis\n" + asDollars(params.api.assetSummary.basisSummation);
                                else return "Basis\n" + " ";
                            },
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            minWidth: 125,
                            resizable: true
                        },
                        {
                            field: "tradeDate",
                            headerName: "Trade Date\n" + true,
                            headerValueGetter: (params) => {
                                if (params.api.assetSummary)
                                    return "Trade Date\n" + dateFormat(params.api.assetSummary.dateMode);
                                else return "Trade Date\n" + " ";
                            },
                            valueFormatter: formatDate,
                            filter: "agDateColumnFilter",
                            type: "rightAligned",
                            minWidth: 125,
                            width: 125,
                            resizable: true
                        }
                    ]
                },
                {
                    headerName: "Data",
                    children: []
                }
            ]
        });

        profile.value = "hi";

        const trim = (val, num = 0) => {
            if (num === 0) return Math.floor(val);

            const d = Math.pow(10, num);
            const e = Math.floor(val * d) / d;
            return e.toFixed(num);
            // return Math.floor(val * d) / d;
        };
        const dateFormat = (dateTime) => {
            const date = new Date(dateTime);
            const year = date.getFullYear();

            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };

        const replaceWithCodes = (name: string) => {
            return name.replace(/schwab/gi, "CS");
        };

        const reportAsset = async () => {
            loading.value = true;
            try {
                if (ticker.value == $route.params.ticker || !$route.params.ticker) return;

                if ($route.params.ticker) ticker.value = await $route.params.ticker;

                displayTicker.value = ticker.value;
                var response = await $http.get("/api/asset/report/" + ticker.value);
                if ((response.status = 200 && response.data.length != 0)) {
                    $success("Showing " + ticker.value);
                    document.title = ticker.value;
                    assetList.value = response.data.singleAssetArrayList;
                    assetSummary.value = response.data.singleAssetSummary;
                    // if (gridApi.value != null) {
                    // 	gridApi.value.assetSummary = assetSummary.value;
                    // } else console.log("ITS NULLLlllllll");
                    gridApi.value.assetSummary = assetSummary.value;
                    gridApi.value.refreshHeader();
                } else {
                    $error("Could Not Find the Ticker: " + ticker.value);
                }
                loading.value = false;
            } catch (err) {
                $error("400 error: Failed to get Report for: " + ticker.value);
                loading.value = false;
            }
        };
        const showHH = async () => {
            //take to new page
        };
        onMounted(() => {
            if ($route.params.ticker) ticker.value = reportAsset();
        });

        watch(() => $route.params.ticker, () => { reportAsset() });

        return {
            loading,
            profile,
            date,
            assetList,
            assetSummary,
            ticker,
            displayTicker,
            defaultColDef,
            columnsDef,
            onGridReady,
            trim,
            asDollars,
            formatDate,
            replaceWithCodes,
            reportAsset,
            showHH
        };
    }
};
</script>
<style lang="scss">
.assetReport table {
    width: 100%;
}

.assetReport th {
    white-space: pre;

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12),
    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(15),
    &:nth-child(16),
    &:nth-child(17),
    &:nth-child(18) {
        text-align: right;
        padding-right: 1.2rem;
    }

    &:nth-child(18) {
        margin-right: 8rem !important;
        // padding-right: 8rem !important;
    }
}

.assetReport tr td,
.assetReport th {
    &:nth-child(1) {
        // max-width: 5.5rem;
        width: 6.65rem;
        // padding-left: 1rem;
    }

    &:nth-child(2) {
        // max-width: 2rem;
        padding-left: 1.2rem;
        // padding-right: 1.5rem;
    }

    &:nth-child(3) {
        width: 1.2rem;
        padding: 0;
    }

    &:nth-child(4) {
        // min-width: 7.2rem !important;
        padding-left: 1.2rem;
        // padding-right: 0rem;
    }

    &:nth-child(5) {
        // padding-left: 1.5rem;
        max-width: 5rem;
    }

    &:nth-child(6) {
        padding-left: 1.2rem;
    }

    &:nth-child(7) {
        max-width: 5rem;
        padding-left: 1.2rem;
    }

    &:nth-child(8) {
        // max-width: 5rem;
        padding-left: 1.2rem;
    }

    &:nth-child(9) {
        // max-width: 5rem;
        // padding-left: 1.2rem;
    }
}

.assetReport o-table-column th {}

.assetReportColumn {
    // width: 1px;
}

.assetReport tr td {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(6),
    // &:nth-child(7),
    // &:nth-child(18)
        {
        text-align: left !important;
    }

    &:nth-child(4) {
        text-align: right !important;
        direction: rtl;
    }

    &:nth-child(18) {
        padding-right: 1.4rem;
    }
}

// li {
// 	display: inline-block;
// }
.topOfAsset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.topOfAssetLists {
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    width: auto;

    & ul {
        padding: 1em;
    }

    margin-right: 8em;
}
</style>
