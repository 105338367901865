//sharePlugin requires the chart to have an id.
const transparentBackground = {
    id: "transparent-background",
    beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'rgba(0,0,0,0)';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}
export {
    transparentBackground
}
