<template>
    <div>
        <!-- <h1>HHID: {{ $route.params.hhID }}</h1>  -->
        <h1>{{ hhName }}</h1>

        <!-- <br /> -->
        <!-- <o-field grouped> -->
        <h1 v-if="loading">Loading...</h1>
        <!-- </o-field> -->


        <br />
        <div>
            <ag-grid-vue class="ag-theme-alpine" :rowData="hhData" domLayout="autoHeight" :defaultColDef="defaultColDef" :columnDefs="summaryColumnDefs.value" @grid-ready="onGridReady"></ag-grid-vue>
        </div>

        <br />
        <div v-for="(account, index) in singleAccountsData" :key="account.AccountID" class="recent-sales-container my-5">
            <h2>{{ singleAccountsData[index].AccountName }}</h2>

            <o-dropdown class="recent-sales-wrap" :mobileModal="false">
                <template #trigger="{ active }">
                    <o-button variant="primary" outlined :key="active" class="toggle-button">
                        <span>{{ active ? "Hide recent sales" : "Show recent sales" }}</span>
                        <i class="icon fas fa-caret-up" v-show="active"></i><i class="icon fas fa-caret-down" v-show="!active" :key="active"></i>
                    </o-button>
                </template>
                <template #default>
                    <ag-grid-vue class="ag-theme-alpine recent-sales" :rowData="singleAccountsData[index].RecentSales" domLayout="autoHeight" :defaultColDef="defaultColDef" :columnDefs="recentSalesColumnDefs.value" :suppressAggFuncInHeader="true" :groupDefaultExpanded="1"></ag-grid-vue>
                </template>
            </o-dropdown>
            <ag-grid-vue class="ag-theme-alpine" :rowData="account.CashAndCashEquivalent" domLayout="500" style="height: 50rem" :defaultColDef="defaultColDef" :columnDefs="singleAccountColumnDefs.value" :suppressAggFuncInHeader="true" :groupDefaultExpanded="1"></ag-grid-vue>
        </div>
    </div>
</template>
<script lang="ts">
import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import AssetNoteButton from "@/components/assetNoteButton.vue";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { useRoute } from "vue-router";
export default {
    props: { hhID: { type: Number } },
    emits: [],
    components: {
        AgGridVue
    },

    setup(props) {
        const $oruga = inject("$oruga") as Record<string, any>;
        const gridApi = ref(null); // Optional - for accessing Grid's API

        // Obtain API from grid's onGridReady event
        const onGridReady = (params) => {
            gridApi.value = params.api;

            params.columnApi.autoSizeAllColumns();
        };

        const defaultColDef = {
            resizible: true,
            sortable: true,
            width: 200,
            filter: "agTextColumnFilter"
        };
        // const customFunc = function (params) {
        // 	var sum = 0;
        // 	params.forEach(function (value){
        // 		sum += Number(value);
        // 	});
        // 	return sum;
        // }

        const currencyFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 0);
        };

        const currencyCentsFormatter = function (params) {
            if (params.value == null) return "";
            return "$" + formatNumber(params, 2);
        };

        const integerFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 0);
        };

        const singleDecimalFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 1);
        };

        const numberFormatter = function (params) {
            if (params.value == null) return "";
            return formatNumber(params, 2);
        };

        const percentFormatter = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 0) + "%";
        };
        const percentFormatter1dec = function (params) {
            if (params.value == null) return "";
            params.value *= 100;
            return formatNumber(params, 1) + "%";
        };
        const outsideLegacyRestricted = function (params) {
            if (params.value == "28") return "L";
            else if (params.value == "24" || params.value == "26" || params.value == "30") return "O";
            else if (
                params.value == "29" ||
                params.value == "31" ||
                params.value == "32" ||
                params.value == "33"
            )
                return "R";
            else return "";
        };

        const formatNumber = function (params, precision = 0) {
            if (params.value == null) return "";
            return parseFloat(params.value)
                .toFixed(precision)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        };
        const formatDate = function (params) {
            // if (params.value == null || params.value == "01/01/1") return "";

            const date = new Date(params.value);
            const year = date.getFullYear();

            if (year == 1) return "";
            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;

            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return month + "/" + day + "/" + year;
        };

        const $http = inject("$http") as any; //for posts and such
        const $moment = inject("$moment") as any; //date math
        const $success = inject("$success") as any; // $success("It worked!")
        const $error = inject("$error") as any;
        const $route = useRoute();

        const loading = ref(false);
        const profile = ref("");
        const date = ref($moment.now());
        const hhData = ref();
        const hhID = ref();
        const singleAccountsData = ref();
        const hhName = ref();

        const summaryColumnDefs = reactive({
            value: [
                {
                    headerName: " ",
                    children: [
                        {
                            field: "accountName",
                            headerName: "Account",
                            // valueFormatter: singleDecimalFormatter,
                            type: "leftAligned",
                            width: 350,
                            resizable: true
                        },
                        {
                            field: "total",
                            headerName: "Total",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 150
                        },
                        {
                            field: "cashPercent",
                            headerName: "Cash",
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            resizable: true,
                            width: 100
                        },
                        {
                            field: "equity",
                            headerName: "Equity",
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            resizable: true,
                            width: 100
                        },
                        {
                            field: "equitySleeve",
                            headerName: "Eq. Sleeve",
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            resizable: true,
                            width: 120
                        },
                        {
                            field: "bondWeight",
                            headerName: "Maturity",
                            valueFormatter: singleDecimalFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 120
                        },
                        {
                            field: "fixedIncome",
                            headerName: "Bonds",
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            resizable: true,
                            width: 100
                        }
                    ]
                },
                {
                    headerName: "Metrics",
                    children: [
                        {
                            field: "StartingBalance",
                            headerName: "1/1 Starting Balance\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        // {
                        //     field: "EndingBalance",
                        //     headerName: "As Of Balance\n",
                        //     columnGroupShow: "open",
                        //     width: 110,
                        //     filter: "agNumberColumnFilter",
                        //     valueFormatter: currencyFormatter,
                        //     type: "rightAligned",
                        //     resizable: true
                        // },
                        {
                            field: "NetWithdrawalDeposit",
                            headerName: "Net W/D\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "WithdrawalDepositPercent",
                            headerName: "W/D %\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "NetFeePerformance",
                            headerName: "Return\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "EqOnlyGrossPerf",
                            headerName: "Eq Only\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "RealizedGainsAndLosses",
                            headerName: "Realized G/L\n",
                            columnGroupShow: "open",
                            width: 110,
                            filter: "agNumberColumnFilter",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true
                        },
                        {
                            field: "notes",
                            headerName: "Notes",
                            resizable: true,
                            minWidth: 500,
                            //cellRenderer: AssetNoteButton

                            // valueFormatter: percentFormatter1dec,
                            // type: "rightAligned"
                        },
                        {
                            field: "omsAmount",
                            headerName: "Outside Model",
                            resizable: true,
                            minWidth: 150,
                            valueFormatter: currencyFormatter,
                            type: "rightAligned"
                        },
                        {
                            field: "AccountCustomFieldMuniState",
                            headerName: "Muni State",
                            resizable: true,
                            minWidth: 150
                        },
                        {
                            field: "muniWeight",
                            headerName: "Muni %",
                            filter: "agNumberColumnFilter",
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            resizable: true,
                            minWidth: 150
                        },
                    ]
                }
            ]
        });

        const singleAccountColumnDefs = reactive({
            ////////////////////////////////////////////////////////////////////////////////////////////////
            value: [
                {
                    headerName: "",
                    children: [
                        {
                            field: "StrategyGroup",
                            rowGroup: true,
                            hide: true,
                            headerName: "StrategyGroup",
                            minWidth: 120,
                            columnGroupShow: "",
                            // filter: "agNumberColumnFilter",
                            // valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            resizable: true
                        },
                        {
                            field: "AccountName",
                            hide: true,
                            headerName: "Account",
                            minWidth: 120,
                            columnGroupShow: "always",
                            // filter: "agNumberColumnFilter",
                            // valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            resizable: true
                        }
                    ]
                },
                {
                    headerName: "Asset",
                    children: [
                        {
                            field: "AssetTicker",
                            headerName: "Ticker",
                            cellRenderer: (params) => {
                                return (
                                    '<a href = "/asset/' +
                                    params.value +
                                    '"  target="_blank"> ' +
                                    params.value +
                                    " </a>"
                                );
                            },
                            hide: true,
                            minWidth: 150,
                            rowGroup: true,
                            columnGroupShow: "always",
                            // valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            aggFunc: "first",
                            resizable: true
                        },
                        {
                            field: "AssetName",
                            headerName: "Asset Name",
                            width: 350,
                            columnGroupShow: "open",
                            // valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            aggFunc: (params) => {
                                if (params.rowNode.level == 0) return "";
                                return params.values[0];
                            },
                            resizable: true
                            // headerValueGetter: (params) => {
                            // 	return "Asset Name";
                            // }
                        },
                        {
                            field: "StrategyID",
                            headerName: "ID",
                            width: 68,
                            columnGroupShow: "open",
                            valueFormatter: outsideLegacyRestricted,
                            type: "rightAligned",
                            aggFunc: "first",
                            resizable: true
                        }
                        // {
                        // 	field: "AssetLevelStrategyName",
                        // 	headerName: "Strategy ID",
                        // 	minWidth: 110,
                        // 	columnGroupShow: "open",
                        // 	valueFormatter: integerFormatter,
                        // 	type: "rightAligned",
                        // 	aggFunc: "first"
                        // }
                        // {
                        // 	field: "AcquiredDate",
                        // 	headerName: "Acquired",
                        // 	minWidth: 120,
                        // 	columnGroupShow: "open",
                        // 	valueFormatter: formatDate,
                        // 	type: "rightAligned",
                        // 	aggFunc: "last"
                        // }
                    ]
                },
                {
                    headerName: "Values",
                    openByDefault: true,
                    children: [
                        {
                            field: "TaxLotUnits",
                            headerName: "Shares",
                            width: 120,
                            columnGroupShow: "open",
                            valueFormatter: integerFormatter,
                            type: "rightAligned",
                            aggFunc: "sum",
                            valueParser: "Number(newValue)",
                            resizable: true

                            // aggFunc: customFunc
                        },
                        {
                            // field: "LastPrice", TaxLotPrice
                            field: "TaxLotPrice",
                            headerName: "Price",
                            width: 120,
                            columnGroupShow: "open",
                            valueFormatter: currencyCentsFormatter,
                            type: "rightAligned",
                            aggFunc: "first",
                            resizable: true
                        },
                        {
                            field: "MarketValue",
                            headerName: "Market Value",
                            width: 150,
                            columnGroupShow: "always",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            aggFunc: "sum",
                            resizable: true
                            // aggFunc: "avg"
                        },
                        {
                            field: "TotalCostBasis",
                            headerName: "Cost Basis",
                            width: 150,
                            columnGroupShow: "open",
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            aggFunc: "sum",
                            resizable: true
                        },
                        {
                            field: "Weighting",
                            headerName: "Weighting",
                            width: 120,
                            columnGroupShow: "open",
                            valueFormatter: percentFormatter1dec,
                            type: "rightAligned",
                            aggFunc: "sum",
                            resizable: true
                        },
                        {
                            field: "AcquiredDate",
                            headerName: "Trade Date",
                            width: 130,
                            columnGroupShow: "open",
                            valueFormatter: formatDate,
                            type: "rightAligned",
                            aggFunc: "last",
                            resizable: true
                        },
                        {
                            field: "Years",
                            headerName: "Yrs.",
                            // minWidth: 60,
                            width: 75,
                            columnGroupShow: "open",
                            valueFormatter: singleDecimalFormatter,
                            type: "rightAligned",
                            aggFunc: "first",
                            resizable: true
                        },
                        {
                            field: "NoteWithMetadata",
                            headerName: "Note",
                            // minWidth: 60,
                            width: 120,
                            columnGroupShow: "open",
                            // valueFormatter: singleDecimalFormatter,
                            // type: "rightAligned",
                            aggFunc: "first",
                            resizable: true,
                            cellRenderer: AssetNoteButton
                        }
                    ]
                }
            ]
        });

        const recentSalesColumnDefs = reactive({
            ////////////////////////////////////////////////////////////////////////////////////////////////
            value: [
                {
                    headerName: "Recent Sales",
                    children: [
                        {
                            field: "TransactionDate",
                            headerName: "Date",
                            filter: "agDateColumnFilter",
                            floatingFilter: true,
                            minWidth: 120,
                            //rowGroup: true,
                            columnGroupShow: "always",
                            //valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            aggFunc: "first",
                            resizable: true,
                            sort: "desc"
                        },
                        {
                            field: "AssetTicker",
                            filter: "agTextColumnFilter",
                            floatingFilter: true,
                            headerName: "Ticker",
                            cellRenderer: (params) => {
                                return (
                                    '<a href = "/asset/' +
                                    params.value +
                                    '"  target="_blank"> ' +
                                    params.value +
                                    " </a>"
                                );
                            },
                            //hide: true,
                            minWidth: 150,
                            //rowGroup: true,
                            columnGroupShow: "always",
                            // valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            aggFunc: "first",
                            resizable: true
                        },
                        {
                            field: "AssetName",
                            headerName: "Asset Name",
                            filter: "agTextColumnFilter",
                            floatingFilter: true,
                            width: 250,
                            columnGroupShow: "always",
                            // valueFormatter: currencyFormatter,
                            type: "leftAligned",
                            // aggFunc: (params) => {
                            // 	if (params.rowNode.level == 0) return "";
                            // 	return params.values[0];
                            // },
                            resizable: true
                            // headerValueGetter: (params) => {
                            // 	return "Asset Name";
                            // }
                        },
                        {
                            field: "TransactionUnits",
                            headerName: "Units",
                            filter: "agNumberColumnFilter",
                            floatingFilter: true,
                            //valueFormatter: singleDecimalFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 120
                        },
                        {
                            field: "TransactionPrice",
                            headerName: "Unit Price",
                            filter: "agNumberColumnFilter",
                            floatingFilter: true,
                            valueFormatter: currencyCentsFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 100
                        },
                        {
                            field: "TransactionValue",
                            headerName: "Value",
                            filter: "agNumberColumnFilter",
                            floatingFilter: true,
                            valueFormatter: currencyFormatter,
                            type: "rightAligned",
                            resizable: true,
                            width: 200
                        }
                    ]
                }
            ]
        });

        const trim = (val, num = 0) => {
            if (num === 0) return Math.floor(val);

            const d = Math.pow(10, num);
            return Math.floor(val * d) / d;
        };

        const asDollars = (val) => {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(val);
        };

        // const formatDate = (dateTime) => {
        // 	const date = new Date(dateTime);
        // 	const year = date.getFullYear();

        // 	let month = (1 + date.getMonth()).toString();
        // 	month = month.length > 1 ? month : "0" + month;

        // 	let day = date.getDate().toString();
        // 	day = day.length > 1 ? day : "0" + day;

        // 	return month + "/" + day + "/" + year;
        // };

        const reportHH = async () => {
            loading.value = true;
            try {
                // var response = await $http.get("/api/asset/report/" + ticker.value); /api/asset/household/658

                if (hhID.value == $route.params.hhID || !$route.params.hhID) return;

                if ($route.params.hhID) hhID.value = $route.params.hhID;

                var nameResponse = await $http.get("/api/account/hhName/" + hhID.value);
                hhName.value = nameResponse.data;

                var response = await $http.get("/api/account/household/" + hhID.value);
                if ((response.status = 200 && response.data.length != 0)) {
                    $success("Showing " + hhName.value);
                    document.title = response.data.accountList[0].accountName;
                    hhData.value = response.data.accountList;
                    // singleAccountsData.value = response.data.accountTables[0].CashAndCashEquivalent;
                    singleAccountsData.value = response.data.singleAccounts;

                    //Add a computed note object to each row
                    for (const account of singleAccountsData.value) {
                        for (const asset of account.CashAndCashEquivalent) {
                            asset.NoteWithMetadata = {
                                AccountID: account.AccountID,
                                Note: asset.Note,
                                NoteTimeStamp: asset.NoteTimeStamp,
                                NoteUser: asset.NoteUser
                            };
                        }
                    }

                    // displayTicker.value = ticker.value;
                } else {
                    $error("Could Not Find the HH: ");
                }
                loading.value = false;
            } catch (err) {
                console.log("Failed to get Report");
                $error("400 error: Failed to get Report for: ");
                loading.value = false;
            }
        };

        onMounted(() => {
            reportHH();
        });

        watch(
            () => $route.params.hhID,
            () => {
                reportHH();
            }
        );

        return {
            loading,
            date,
            hhData,
            singleAccountsData,
            summaryColumnDefs,
            singleAccountColumnDefs,
            recentSalesColumnDefs,
            defaultColDef,
            hhName,
            trim,
            asDollars,
            formatDate,
            reportHH,
            onGridReady
        };
    }
};
</script>

<style lang="scss" scoped>
.toggle-button {
    width: 12rem;
}
</style>
<style lang="scss">
.asset-note-button {
    display: flex;
    flex-direction: row;
    gap: 0.6em;

    & .button {
        padding: 0 0.3em;
        height: 1.7em;
        margin-top: 0.3em;
    }
}

.o-drop.dropdown.recent-sales-wrap {
    flex-direction: column;
    width: 100%;

    margin-top: 1em;
    margin-bottom: 2em;

    & .o-drop__menu {
        position: static;
        display: block;
        width: 100%;
    }

    .o-btn__label {
        display: flex;
        justify-content: space-between;

        & .o-btn__icon {
            margin-right: 0;
        }
    }
}

.ag-header {
    top: 0;
    position: sticky;
    // style="top: 0; position: sticky"
}

// .singleHousehold {
//     width: 45rem;
//     top: 0;
//     position: sticky;
// }

// .singleHousehold tr td,
// .singleHousehold th {t
//     &:nth-child(1) {
//         // max-width: 5.5rem;
//         min-width: 25rem;
//         // padding-left: 1rem;
//     }

//     text-align: left;
// }

// // .assetReportColumn {
// // 	// width: 1px;
// // }
// .singleHousehold tr td {
//     &:nth-child(1) {
//         text-align: left !important;
//         // width: 30rem;
//         // direction: rtl;
//     }

// &:nth-child(4) {
// 	text-align: right !important;
// 	direction: rtl;
// }
//}
</style>
