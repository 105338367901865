<template>
	<div class="tradeForm">
		<form @submit.prevent="submitForm">
			<div v-for="(trade, index) in trades" :key="index" class="trade">
				<!-- <h3 class="heading">Trade {{ index + 1 }}</h3> -->
				<div class="header">
					<h3 class="heading">Buy {{ index + 1 }}</h3>
					<button @click="removeTrade(index)" class="removeButton">X</button>
				</div>
				<div class="inputGroup">
					<label class="tradeLabel">Ticker:</label>
					<input
						v-model="trade.ticker"
						type="text"
						required
						class="tradeInput tradeInput--ticker" />
					<label class="tradeLabel">Percent:</label>
					<input
						v-model="trade.percent"
						type="number"
						min="0"
						step="0.01"
						required
						class="tradeInput tradeInput--percent" />
					<label class="tradeLabel">Price:</label>
					<input
						v-model="trade.price"
						type="number"
						min="0"
						step="0.01"
						required
						class="tradeInput tradeInput--price" />
				</div>
				<!-- <hr class="trade__divider" /> -->
				<!-- <o-button type="button" @click="removeTrade(index)">Remove Trade</o-button> -->
			</div>
			<o-button type="button" @click="addTrade">Add Trade</o-button>
			<o-button type="submit" @click="submitForm" class="form__button">
				Initiate Live Trade
			</o-button>
		</form>
	</div>
</template>

<script>
	import { ref } from "vue";

	export default {
		props: {
			trades: {
				type: Array,
				required: true
			}
		},
		emits: ["submit"],
		setup(props, { emit }) {
			// setup(props, context) {
			const trades = ref(props.trades);

			const addTrade = () => {
				trades.value.push({
					ticker: "",
					percent: "",
					price: ""
				});
			};

			const removeTrade = (index) => {
				trades.value.splice(index, 1);
			};

			const submitForm = () => {
				// context.emit(“submit", myArray);
				emit("submit", trades.value);
				emit("close");
				// console.log(trades);
			};

			return {
				// trades,
				addTrade,
				removeTrade,
				submitForm
			};
		}
	};
</script>



<style scoped>
	.tradeForm {
		max-width: 800px;
		margin: 0 auto;
		padding: 20px;
		font-family: Arial, Helvetica, sans-serif;
	}

	.trade {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
	}

	.heading {
		font-size: 24px;
		margin-top: 0;
	}

	.inputGroup {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.tradeLabel {
		flex: 0 0 100px;
		font-size: 16px;
		margin-right: 2px;
	}

	.tradeInput {
		flex: 1;
		font-size: 12px;
		padding: 10px;
		border: none;
		border-radius: 5px;
		background-color: #f5f5f5;
		margin-left: -20px;
	}
	.tradeInput {
		flex: 1;
		font-size: 16px;
		padding: 10px;
		border: none;
		border-radius: 5px;
		background-color: #f5f5f5;
		width: calc(33.33% - 10px);
		margin-right: 10px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.heading {
		font-size: 24px;
		margin-top: 0;
	}
	.removeButton {
		font-size: 16px;
		color: #ff0000;
		background: none;
		border: none;
		cursor: pointer;
	}

	.removeButton {
		font-size: 16px;
		color: #ff0000;
		background: none;
		border: none;
		cursor: pointer;
	}

	.trade__remove {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 10px;
	}

	.removeButton {
		font-size: 16px;
		font-weight: bold;
		color: #f44336;
		background: none;
		border: none;
		cursor: pointer;
	}

	.removeButton:hover {
		color: #d32f2f;
	}

	.removeButton:focus {
		outline: none;
	}
</style>
